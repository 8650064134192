import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["launcher", "unreadCount"];

  initialize() {
  }

  connect() {
    window.Intercom('onShow', () => {
      this.launcherTarget.classList.add('intercom-open');
    });

    window.Intercom('onHide', () =>
      this.launcherTarget.classList.remove('intercom-open'));

    window.Intercom('onUnreadCountChange', count => {
      this.unreadCountTarget.textContent = count;
      if (count) {
        this.unreadCountTarget.classList.add('active');
      } else {
        this.unreadCountTarget.classList.remove('active');
      }
    });

    document.addEventListener("intercom:hide_button", this.onHideButton);
    document.addEventListener("intercom:show_button", this.onShowButton);
  }

  disconnect() {
    this.launcherTarget.classList.remove('intercom-open');
  }

  onHideButton = () => {
    this.launcherTarget.classList.add("hidden");
  }

  onShowButton = () => {
    this.launcherTarget.classList.remove("hidden");
  }
}
