import { Controller } from 'stimulus';

const SHOW_TEXT_STRING = 'Show Number';
const HIDE_TEXT_STRING = 'Hide Number';

export default class extends Controller {
  static targets = [
    'button',
    'text',
  ];

  initialize() {
    this.isMasked = false;
    this.unmaskedText = this.textTarget.textContent.trim();
    this.maskedText = this.createMask();
    this.toggleMask();
  }

  onButtonClick = () => {
    this.toggleMask();
    this.toggleButtonText();
  };

  toggleMask = () => {
    this.isMasked = !this.isMasked;
    this.textTarget.innerText = this.isMasked ? this.maskedText : this.unmaskedText;
  };

  toggleButtonText = () => {
    this.buttonTarget.innerText = this.isMasked ? SHOW_TEXT_STRING : HIDE_TEXT_STRING;
  };

  createMask = () => {
    const text = this.unmaskedText;
    const maskLength = text.length - this.textShown;
    var mask = '';

    for (let i = 0; i < maskLength; i++) {
      mask += 'X';
    };

    mask += this.unmaskedText.substring(maskLength);
    return mask;
  };

  get textShown() {
    return this.data.get('text-shown');
  }
}
