import { Controller } from "stimulus";

const EXPANDED_CLASS = "is-expanded";
const TOGGLED_CLASS = "toggled";

export default class extends Controller {
  static targets = [
    "item",
    "menu",
    "menuBtn",
  ]

  onMenuBtnClick() {
    this.menuBtnTarget.classList.toggle(TOGGLED_CLASS);
    this.menuTarget.classList.toggle(TOGGLED_CLASS);
  }

  onMenuItemClick = e => {
    const menuItem = e.currentTarget;
    const isExpanded = menuItem.classList.contains(EXPANDED_CLASS);

    this.closeMenus();
    menuItem.classList.toggle(EXPANDED_CLASS, !isExpanded);
  }

  closeMenus() {
    this.itemTargets.forEach(item => item.classList.remove(EXPANDED_CLASS));
  }
}