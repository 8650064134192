import { Controller } from "stimulus"
import { Turbo } from '@hotwired/turbo-rails';
import Url from "domurl";

import { findTimeZone } from "../js/helper_functions";

export default class extends Controller {
  initialize() {
  }

  connect() {
    let tz = findTimeZone();
    let url = new Url(this.url);
    url.query.tz = tz;

    Turbo.visit(url.toString(), { action: "replace" });
  }

  get url() {
    return this.data.get("url");
  }
}
