import { Controller } from "stimulus";
import TimeoutPromise from "../js/timeout_promise";

export default class extends Controller {
  initialize() {
    this.focusFirstError();
  }

  connect() {
    new TimeoutPromise().timeout(500).then(() => {
      this.element.classList.remove('is-hidden');
    });

    new TimeoutPromise().timeout(5000).then(() => {
        this.element.classList.add('is-hidden');
    });
  }

  focusFirstError() {
    const firstError = document.querySelector('.form-control.error');
    if (firstError) firstError.focus();
  }

  close() {
    this.element.classList.add("is-hidden");
  }
}
