$(document).on('submit', 'form[method=get]', function(e) {
  // Handle "GET forms" (forms using method: :get) via turbolinks
  // https://github.com/turbolinks/turbolinks/issues/272
  e.preventDefault();
  var form = $(this);
  Turbo.visit(form.attr("action") + "?" + form.serialize());
});

document.addEventListener("turbo:before-cache", function() {
  // Remove alerts from the cached page so that they are not displayed
  // again when the page is revisited from cache.
  $(".alert").remove();
});

document.addEventListener("turbo:load", function() {
  // Focus the first form element as a convenience to the user
  if ($(".form-control.error:first").length == 0) {
    $(".form-control:first").focus();
  }

  // After turbolinks reloads the page, the video and audio elements
  // automatically pause, so we need to resume them.
  // Ignore not allowed errors due to user denying autoplay permissions
  const videoElements = document.querySelectorAll("video");
  videoElements.forEach(video => {
    video.play().catch(e => {
      if (e.name !== "NotAllowedError") throw e;
    })
  })
  const audioElements = document.querySelectorAll("video");
  audioElements.forEach(audio => {
    audio.play().catch(e => {
      if (e.name !== "NotAllowedError") throw e;
    })
  })

  if (window.Intercom != undefined) {
    // Tell intercom that a new page was loaded
    window.Intercom("update");
  }

  if (window.objectFitPolyfill != undefined) {
    window.objectFitPolyfill();
  }
});
