import flatpickr from "flatpickr";
import tippy from "tippy.js";
import moment from "moment";
import { Controller } from "stimulus";
import 'tippy.js/themes/light-border.css';
import { eventDispatcher} from "../js/event_dispatcher";

export default class extends Controller {
  static targets = [
    "timeSwitch",
    "timeSwitchMask",
    "timeInput",
    "calendarExtras"
  ]

  initialize() {
    this.date = "";
    this.time = "";
    this.setDateTimeFromUrl();
    this.enableFlatpickr();
    const calendar = document.querySelector(".flatpickr-calendar");
    const dateTime = document.querySelector(".date-time");
    const calendarExtras = document.querySelector(".calendar-extras");
    calendar.appendChild(calendarExtras);

    tippy.setDefaultProps({
      duration: 0,
      arrow: true,
      theme: "light-border"
    })
    tippy('.date-time', {
      appendTo: this.element,
      content: calendar,
      interactive: true,
      trigger: 'click',
      placement: 'bottom-start',
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            enabled: true
          },
        ],
      },
      zIndex: 2,
      onShown() {
        dateTime.classList.add("highlight");
      },
      onHidden() {
        dateTime.classList.remove("highlight");
      }
    })
  }

  connect() {
    document.addEventListener('keyup', this.handleEscapeKeyPress);
  }

  disconnect() {
    this.flatpickr.destroy();
    document.removeEventListener('keyup', this.handleEscapeKeyPress);
  }

  enableFlatpickr = () => {
    this.flatpickr = flatpickr('.date-time', {
      appendTo: this.element,
      inline: true,
      defaultDate: this.date || "today",
      minDate: 'today',
      onChange: this.onDateSelect,
      monthSelectorType: 'static',
      yearSelectorType: 'static'
    });
  }

  handleEscapeKeyPress = e => {
    if (e.key === "Escape") {
      this.hideTippy();
    }
  }

  hideTippy() {
    const popper = document.querySelector('.tippy-popper');
    if (!popper) return;

    const instance = popper._tippy;
    instance.hide();
  }

  onDateSelect = (selectedDates, dateStr, instance) => {
    this.date = dateStr;
  }

  applyDateFilter() {
    const time = this.timeSwitchTarget.checked ? this.timeInputTarget.value : ""
    eventDispatcher.dispatch("calendar_filter:updated", { date: this.date, time });
    this.hideTippy();
  }

  setDateTimeFromUrl = () => {
    let url = new URL(window.location.href);
    if (!url) return;
    this.date = url.searchParams.has("date") ? url.searchParams.get("date") : moment().format("YYYY-MM-DD");
    this.time = url.searchParams.has("time") ? url.searchParams.get("time") : "";
    this.timeInputTarget.value = this.time || "12:00";
    this.timeSwitchTarget.checked = this.time ? true : false;
    this.toggleTimeSwitch();
  }

  toggleTimeSwitch() {
    if (this.timeSwitchTarget.checked) {
      this.timeSwitchMaskTarget.style.display = "none";
    } else {
      this.timeSwitchMaskTarget.style.display = "inherit";
    }
  }
}
