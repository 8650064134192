import flatpickr from "flatpickr";
import { Controller } from "stimulus"
import { eventDispatcher } from "../../shared/js/event_dispatcher";
import { hideOnClickOutside } from "../../shared/js/helper_functions";


export default class extends Controller {
  initialize() {
    this.flatpickr = null;
  }

  connect() {
    this.enableFlatpickr();
  }

  disconnect() {
    this.flatpickr.destroy();
  }

  enableFlatpickr = () => {
    this.flatpickr = flatpickr('.datepicker', {
      inline : true,
      onChange: this.onDateSelect,
      defaultDate: this.date,
      enable: this.availableDates,
      minDate: this.availableDates[0],
      maxDate: this.availableDates[this.availableDates.length - 1],
      monthSelectorType: 'static',
      yearSelectorType: 'static'
    });
  }

  onDateSelect = (selectedDates, dateStr, instance) => {
    eventDispatcher.dispatch("listing_flatpickr:changed", { dateStr });
  }

  openFlatpickr = event => {
    event.stopPropagation();
    const calendar = document.querySelector(".flatpickr-calendar");
    calendar.classList.toggle("open");
    hideOnClickOutside(calendar, "open", "remove");
  }

  get availableDates() {
    return JSON.parse(this.data.get("availableDates"));
  }
}