import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "passwordField",
    "confirmField",
    "passwordTooltip",
    "passwordMessage",
    "passwordStrengthMessage",
    "passwordStrengthSuggestion",
    "passwordStrengthBar",
    "passwordStrengthLine",
    "confirmTooltip",
    "confirmMessage"
  ]

  connect(){
    this.zxcvbn = require('zxcvbn')
    this.initialStrengthMessage = this.passwordStrengthMessageTarget.innerHTML
    this.minimumPasswordScore = 3;
  }

  checkStrength = () => {
    let passwordMin = 8;
    let password = this.passwordFieldTarget.value;
    let passwordTooltip = this.passwordTooltipTarget;
    let passwordMessage = this.passwordMessageTarget;
    let passwordStrengthMessage = this.passwordStrengthMessageTarget;

    let strength = this.zxcvbn(password)
    let feedback = strength.feedback;
    let strengthCheck = (strength.score >= this.minimumPasswordScore)
    let lengthCheck = (password.length >= passwordMin)
    let warningMessage = (feedback.warning.length > 0 ? feedback.warning : feedback.suggestions[0]);

    this.updateStrengthBar(strength.score);

    // only show confirm tooltip if password has input
    if (password.length == 0) {
      passwordTooltip.classList.add('is-hidden');
      return
    } else {
      passwordTooltip.classList.remove('is-hidden');
    }

    // toggle icon depending on the length of the password
    if (lengthCheck) {
      passwordMessage.classList.add('is-valid');
    } else {
      passwordMessage.classList.remove('is-valid');
    }

    if (strengthCheck) {
      passwordStrengthMessage.classList.add('is-valid');
      passwordStrengthMessage.innerHTML = this.initialStrengthMessage
    } else {
      passwordStrengthMessage.classList.remove('is-valid');
      passwordStrengthMessage.innerHTML = warningMessage;
    }

    if (lengthCheck && strengthCheck) {
      setTimeout(() => {
        passwordTooltip.classList.add('is-hidden');
      }, 1250);
    }
  }

  checkMatch = event => {
    let password = this.passwordFieldTarget.value;
    let confirmed = event.target.value;
    let confirmTooltip = this.confirmTooltipTarget;
    let confirmMessage = this.confirmMessageTarget;

    // only show confirm tooltip if password or confirmed fields have input
    if (password.length == 0 && confirmed.length == 0) {
      confirmTooltip.classList.add('is-hidden');
    } else {
      confirmTooltip.classList.remove('is-hidden');
    }

    // toggle icon depending on the length of the password
    if (password == confirmed) {
      confirmMessage.classList.add('is-valid');
      setTimeout(() => {
        confirmTooltip.classList.add('is-hidden');
      }, 1250);
    } else {
      confirmMessage.classList.remove('is-valid');
    }
  }

  updateStrengthBar(score){
    let password = this.passwordFieldTarget.value;
    let strengthBar = this.passwordStrengthBarTarget;
    let strengthLine = this.passwordStrengthLineTarget;
    let maximumScore = 4;
    // Scores start from 0 meaning number of scores is equal to maximum number + 1
    let numberOfScores = maximumScore + 1;
    // Percentage to go up each score needs to be 100 / number of scores.
    let progress = (score + 1) * (100 / numberOfScores);

    if (password.length == 0){
      strengthLine.style.width = "0%";
      return
    }

    strengthBar.classList.remove("error", "warning", "success");

    if (score == 0) {
      strengthBar.classList.add("error")
    } else if (score > 0 && score < this.minimumPasswordScore) {
      strengthBar.classList.add("warning")
    } else if(score >= this.minimumPasswordScore) {
      strengthBar.classList.add("success")
    }

    strengthLine.style.width = progress + "%";
  }
}
