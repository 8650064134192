import TimeoutPromise from "../js/timeout_promise";

class NotifyService {
  constructor() {
  }

  notify(type, message) {
    const alert = document.querySelector('.notify');
    const alertInner = alert.querySelector('.alert-popup');
    const alertMessage = alert.querySelector('.alert-message');
    const alertClass = type === 'warning' ? 'alert-warning' : 'alert-info';

    alertInner.classList.add(alertClass);
    alertMessage.innerHTML = message;
    alert.classList.remove("is-hidden");

    new TimeoutPromise().timeout(4000).then(() => {
      alert.classList.add("is-hidden");
    });
  }
}

export let notifyService = new NotifyService();
