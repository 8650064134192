import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "specialtiesList",
    "toggleButton"
  ]

  initialize() {
    if (this.hasSpecialtiesListTarget) {
      this.displaySpecialtiesToggle();
      window.addEventListener("resize", this.displaySpecialtiesToggle);
    }
  }

  disconnect() {
    if (this.hasSpecialtiesListTarget) {
      window.removeEventListener("resize", this.displaySpecialtiesToggle);
    }
  }

  toggleSpecialties = () => {
    const collapsed = this.element.classList.toggle("collapsed");
    this.toggleButtonTarget.innerText = collapsed ? "View More" : "View Less";
  }

  displaySpecialtiesToggle = () => {
    if (this.specialtiesListTarget.scrollHeight <= 78) {
      this.toggleButtonTarget.classList.add("hidden");
    } else {
      this.toggleButtonTarget.classList.remove("hidden");
    }
  }
}
