import { Controller } from "stimulus";
import ClipboardJS from "clipboard";

export default class extends Controller {
  static targets = [
    "code",
    "codeTitle",
  ]

  initialize() {
    this.clipboard = new ClipboardJS(this.codeTarget, {
      target: function(trigger) {
        return trigger;
      }
    })

    this.clipboard.on('success', e => {
      e.clearSelection();
      this.toggleCodeCopiedClass();
      setTimeout(this.toggleCodeCopiedClass, 1500);
    });
  }

  toggleCodeCopiedClass = () => {
    this.codeTarget.classList.toggle("code-copied");
    this.codeTitleTargets.forEach (codeTitle => {
      codeTitle.classList.toggle("is-visible");
    });
  }
}
