import { Controller } from "stimulus";
import Rollbar from "rollbar";
import { hideOnClickOutside, fetchJSON }  from "./../js/helper_functions";
import { eventDispatcher} from "../../application/js/event_dispatcher";
import { notifyService } from "../js/notify_service";

const ENTER_KEYCODE = 13;
const BACKSPACE_KEYCODE = 8;

export default class extends Controller {
  static values = {
    purchaseOrderId: String
  }

  static targets = [
    "input",
    "searchbar",
    "searchbarTop",
    "searchBrands",
    "searchProductTypes",
    "searchResults",
    "matchedBrands",
    "matchedBrandsList",
    "matchedProductTypes",
    "matchedProductTypesList",
    "selectedTags",
    "searchButtonContainer",
    "searchTextContainer",
    "searchText",
  ];

  initialize() {
    this.productResults = document.getElementById("product-results");
    this.productResultsContent = this.productResults.querySelector('.product-results-content');
    this.loadingSpinner = this.productResults.querySelector('.spinner');
    this.emptySearchContent;

    // Matched brands, productTypes and dietary needs based on key input
    this.brandMatches = [];
    this.productTypeMatches = [];

    // Selected brands, productTypes and dietary needs based on click
    // These data are sent through params to backend
    this.selectedBrands = [];
    this.selectedProductTypes = [];

    // Free text search params
    this.textSearchParams = [];
    this.testCodeParam = null;

    // Tags selected on click.
    // These hold same data as above, but in HTML format for views only
    this.selectedTags = [];

    this.brands = [];
    this.brandsHash = {};
    this.productTypes = [];

    this.updateSearchTerms();
  }

  connect() {
    document.addEventListener('keyup', this.handleEscapeKeyPress);
    document.addEventListener('keyup', this.watchInput);
    document.addEventListener("order:results:load", this.handleNewPage);
    document.addEventListener("order:results:loading", this.showLoading);
  }

  disconnect() {
    document.removeEventListener('keyup', this.handleEscapeKeyPress);
    document.removeEventListener('keyup', this.watchInput);
    document.removeEventListener("order:results:load", this.handleNewPage);
    document.removeEventListener("order:results:loading", this.showLoading);
  }

  handleEscapeKeyPress = e => {
    if (e.key === "Escape") {
      this.closeDropdown();
    }
  }

  watchInput = () => {
    this.searchTextTarget.textContent = `Search for '${this.inputTarget.value.trim()}'`;
  }

  handleNewPage = e => {
    let { page, purchaseOrderID, method } = e.detail;
    this.handleSearchButtonClick(e, page, purchaseOrderID, true, method);
  }

  showLoading = e => {
    this.toggleLoadingOverlay(true);
  }

  // Match brands, productTypes and dietary needs to user input string
  updateSearchResults() {
    let trimmedSearchValue = this.searchValue.trim();
    let searchSubString = new RegExp(trimmedSearchValue, 'i');

    this.brandMatches = this.brands
      .filter(brand => {
        return (brand && brand.name.match(searchSubString));
      })
      .sort((x, y) => {
        return (x.name.match(searchSubString).index - y.name.match(searchSubString).index);
      });

    this.productTypeMatches = this.productTypes
      .filter(productType => {
        return (productType && productType.name.match(searchSubString));
      })
      .sort((x, y) => {
        return (x.name.match(searchSubString).index - y.name.match(searchSubString).index);
      })

    this.showSearchResults();
    this.updateVisibility();
    this.toggleSearchButton();
  }

  // Retrieve first 4 of brand, productType and dietary matches and show in DOM
  showSearchResults() {
    this.matchedBrandsListTarget.innerHTML = this.brandMatches.map(brand => {
      return `<li class="brand" data-id="${brand.id}" data-slug="${brand.slug}">${brand.name}</li>`;
    }).join('');

    this.matchedProductTypesListTarget.innerHTML = this.productTypeMatches.map(productType => {
      return `<li class="productType" data-id="${productType.id}" data-slug="${productType.slug}">${productType.name}</li>`;
    }).join('');
  }

  // Set inital 'results' of each section to all items
  setDefaults() {
    this.matchedBrandsListTarget.innerHTML = this.brands.map(brand => {
      return `<li class="brand" data-id="${brand.id}" data-slug="${brand.slug}">${brand.name}</li>`;
    }).join('');

    this.matchedProductTypesListTarget.innerHTML = this.productTypes.map(productType => {
      return `<li class="productType" data-id="${productType.id}" data-slug="${productType.slug}">${productType.name}</li>`;
    }).join('');
  }

  // Update visibility of headers and containers based on results
  updateVisibility() {
    const topOffset = this.searchbarTopTarget.offsetHeight;
    if (!this.matchedBrandsListTarget.hasChildNodes()) {
      this.searchResultsTarget.style.top = null;
      this.searchBrandsTarget.classList.add("hidden");
    } else {
      this.searchResultsTarget.style.top = `${topOffset}px`;
      this.searchBrandsTarget.classList.remove("hidden");
      this.addHighlight();
    }

    if (!this.matchedProductTypesListTarget.hasChildNodes()) {
      this.searchProductTypesTarget.classList.add("hidden");
    } else {
      this.searchProductTypesTarget.classList.remove("hidden");
      this.addHighlight();
    }

    if (this.inputTarget.value.trim() === "") {
      this.searchTextContainerTarget.classList.add("hidden");
    } else {
      this.searchTextContainerTarget.classList.remove("hidden");
      this.addHighlight();
    }

    if (!this.matchedBrandsListTarget.hasChildNodes() &&
        !this.matchedProductTypesListTarget.hasChildNodes() &&
        this.inputTarget.value === "") {
      this.removeHighlight();
    }
  }

  // Move clicked tag from list to selected tags in search bar
  handleSelect(e) {
    let target = e.target;
    let tag = { id: target.dataset.id, name: target.textContent, slug: target.dataset.slug };

    if (target.parentElement.className === "matched-brands-list") {
      this.selectBrand(tag);
    } else if (target.parentElement.className === "matched-product-types-list") {
      this.selectProductType(tag);
    } else if (target.parentElement.className === "search-text") {
      this.selectSearchText();
    }

    this.updateFields();
    this.handleSearchButtonClick();
    this.inputTarget.blur();
  }

  updateFields() {
    this.inputTarget.value = "";
    this.inputTarget.focus();
    this.setDefaults();
    this.updateSelectedTags();
    this.updateVisibility();
    this.toggleSearchButton();
  }

  selectBrand = tag => {
    const i = this.brands.findIndex(brand => brand.slug === tag.slug);
    if (i === -1) return;
    const selectedBrand = this.brands.splice(i, 1)[0];
    this.selectedBrands.push(selectedBrand);
    this.selectedTags.push(`<li class="brand" data-id="${selectedBrand.id}" data-slug="${(selectedBrand.slug)}">${selectedBrand.name}</li>`);
  }

  selectProductType = tag => {
    const i = this.productTypes.findIndex(productType => productType.slug === tag.slug);
    if (i === -1) return;
    const selectedProductType = this.productTypes.splice(i, 1)[0];
    this.selectedProductTypes.push(selectedProductType);
    this.selectedTags.push(`<li class="productType" data-id="${selectedProductType.id}" data-slug="${selectedProductType.slug}">${selectedProductType.name}</li>`);
  }

  selectSearchText = () => {
    const searchText = this.inputTarget.value.trim();
    const isTestCode = /^\d{4,}\w*$/.test(searchText);
    // Remove any existing test code tags
    if (isTestCode && this.testCodeParam) {
      const oldTagIndex = this.selectedTags.indexOf(`<li class="test-code">${this.testCodeParam}</li>`);
      this.selectedTags.splice(oldTagIndex, 1);
    }
    isTestCode ? this.testCodeParam = searchText : this.textSearchParams.push(searchText);
    const className = isTestCode ? "test-code" : "text";
    this.selectedTags.push(`<li class="${className}">${searchText}</li>`);
    this.inputTarget.value = "";
  }

  // Move clicked tag from search bar back to list
  handleDeselect(event) {
    let target = event.target;

    // Remove from data array
    this.deleteTag(target);

    // Remove from view array
    let j = this.selectedTags.indexOf(target.outerHTML);
    this.selectedTags.splice(j, 1);

    this.updateSearchResults();
    this.updateSelectedTags();
    this.handleSearchButtonClick();
  }

  // Helper method for removing tag brand, productType from data arrays
  deleteTag(target) {
    const tag = { id: target.dataset.id, name: target.innerHTML };
    const tagClass = target.className;
    let index;

    switch (tagClass) {
      case "brand":
        index = this.selectedBrands.findIndex(brand => brand.id === tag.id);
        const clickedBrandTag = this.selectedBrands.splice(index, 1)[0];
        this.brands.push(clickedBrandTag);
        this.sortArrayByName(this.brands);
        break;

      case "productType":
        index = this.selectedProductTypes.findIndex(brand => brand.id === tag.id);
        const clickedProductTypeTag = this.selectedProductTypes.splice(index, 1)[0];
        this.productTypes.push(clickedProductTypeTag);
        this.sortArrayByName(this.productTypes);
        break;

      case "text":
        index = this.textSearchParams.indexOf(tag.name);
        this.textSearchParams.splice(index, 1)[0];
        break;

      case "test-code":
        this.testCodeParam = null;
        break;

      default:
        break;
    }
  }

  sortArrayByName(array) {
    array.sort((a, b) => {
      const nameA = a.name.toLowerCase()
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    return array;
  }

  // Delete the most recently added tag from list of selected tags with backspace
  // Handle Enter
  handleKeyDown(event) {
    if (event.keyCode === BACKSPACE_KEYCODE && this.inputTarget.value === "" && this.selectedTags.length > 0) {
      this.selectedTags.pop();
      let target = this.selectedTagsTarget.lastChild;
      this.deleteTag(target);
      this.updateSelectedTags();
      this.toggleEmptySearchContent(false);
    } else if (event.keyCode === ENTER_KEYCODE && this.inputTarget.value !== "") {
      event.preventDefault();
      this.selectSearchText();
      this.updateFields();
      this.handleSearchButtonClick();
      this.inputTarget.blur();
    } else if (event.keyCode === ENTER_KEYCODE && this.inputTarget.value === "") {
      event.preventDefault();
      this.handleSearchButtonClick();
      this.inputTarget.blur();
    }
  }

  selectTopTag() {
    if (this.brandMatches.length > 0) {
      this.selectBrand(this.brandMatches[0]);
    } else if (this.productTypeMatches.length > 0) {
      this.selectProductType(this.productTypeMatches[0]);
    } else {
      this.selectSearchText();
    }

    this.updateFields();
  }

  // Update list of selected tags in search bar
  updateSelectedTags() {
    this.selectedTagsTarget.innerHTML = this.selectedTags.join('');

    if (this.selectedTags.length === 0) {
      this.selectedTagsTarget.classList.add("hidden");
    } else {
      this.selectedTagsTarget.classList.remove("hidden");
    }
  }

  // Retrieve list of productTypes from backend
  fetchProductTypes() {
    let url = new URL(`${this.apiUrl}/product_types`);
    return fetchJSON(url);
  }

  // Retrieve list of brands from backend
  fetchBrands() {
    let url = new URL(`${this.apiUrl}/brands`);
    return fetchJSON(url);
  }

  updateSearchTerms() {
    const promises = [
      this.fetchBrands(),
      this.fetchProductTypes()
    ]

    Promise.all(promises).then(results => {
      [this.brands, this.productTypes] = results.map(json => (
        json.map(taxon => ({
          id: taxon.id,
          name: taxon.name,
          slug: taxon.slug
        }))
      ))

      this.inputTarget.disabled = false;
      this.searchbarTarget.classList.add("ready");
    }).catch(e => {
      notifyService.notify("warning", "Could not load search. Please try again later.");
      Rollbar.error(e);
    });
  }

  fetchProducts = async url => {
    let response;
    try {
      response = await fetchTurbo(url);
    } catch (e) {
      throw new Error("Failed to fetch products");
    }
  }

  // Add the extra attributes from product properties to each product
  mergeProductProperties(product, productProperties) {
    product.relationships.product_properties.data.forEach(prop => {
      const propAttributes = productProperties[prop.id];
      product.attributes[propAttributes.name] = propAttributes.value;
    })
  }

  constructImageUrl(product, productImages) {
    const image = product.relationships.images.data[0];
    if (!image) return "";
    return new URL(this.apiUrl).origin + productImages[image.id].styles[1].url;
  }

  // Create id lookup hash for included product properties, variants and images
  hashIncludedItems(includedItems) {
    const productProperties = {};
    const productVariants = {};
    const productImages = {};
    includedItems.forEach(item => {
      if (item.type === "product_property") {
        productProperties[item.id] = item.attributes;
      } else if (item.type === "variant") {
        productVariants[item.id] = item.attributes;
      } else if (item.type === "image") {
        productImages[item.id] = item.attributes;
      }
    })
    return {
      productProperties,
      productVariants,
      productImages
    };
  }

  // Append search params to URL and trigger request for practitioners matching search data
  handleSearchButtonClick(event = {}, page = 1, purchaseOrderID = this.purchaseOrderIdValue, scrollEnable = false, update_method = "update") {
    let fetchPromises = [];

    this.closeDropdown();
    this.toggleEmptySearchContent(false);
    this.toggleLoadingOverlay(true);

    // fetch all products for each brand selected
    const productTypeSlugs = this.selectedProductTypes.map(type => type.slug);
    const brandSlugs = this.selectedBrands.map(brand => brand.slug);

    const url = this.buildUrl(page, purchaseOrderID, brandSlugs, productTypeSlugs, this.textSearchParams, update_method);

    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      }
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then((data) => {
      this.toggleLoadingOverlay(false);
      if (update_method == "update") {
        eventDispatcher.dispatch("order:results:resetPageCount");
      }

      eventDispatcher.dispatch("order:results:enableScroll")
    })
    .catch(e => Rollbar.error(e))
  }

  toggleEmptySearchContent(enable) {
    this.productResultsContent.classList.toggle("no-matches", enable);
    if (enable) {
      this.productResultsContent.append(this.createEmptySearchContent());
    } else if (this.emptySearchContent) {
      this.productResultsContent.removeChild(this.emptySearchContent);
      this.emptySearchContent = null;
    }
  }

  buildUrl(page = 1, purchaseOrderID = null, brand_slugs = [], product_type_slugs = [], search_terms = [], update_method = "replace") {
    let url = new URL(`${this.apiUrl}/products`);
    url.searchParams.append("page", page);
    url.searchParams.append("purchase_order_id", purchaseOrderID);
    url.searchParams.append("filter[brand_slugs]", brand_slugs.join(","));
    url.searchParams.append("filter[product_type_slugs]", product_type_slugs.join(","));
    const searchTermQuery = `filter%5Bsearch_terms%5D=${search_terms.map(encodeURI).join(",")}`;
    url = this.selectSorting(url, brand_slugs, product_type_slugs, search_terms)
    url.searchParams.append("update_method", update_method);
    url = new URL(`${url}&${searchTermQuery}`)
    return url;
  }

  selectSorting(url, brand_slugs, product_type_slugs, search_terms) {
    if(brand_slugs.length !== 0 || product_type_slugs.length !== 0 || search_terms.length === 0){
      url.searchParams.append("sort", "product_name");
    }
    return url
  }

  toggleLoadingOverlay(enable, hideSpinner = false) {
    this.productResults.classList.toggle("loading", enable);
    this.loadingSpinner.classList.toggle("hide-spinner", hideSpinner);
  }

  createEmptySearchContent() {
    let emptySearchContentDiv = document.createElement("div");
    emptySearchContentDiv.className = "product-results-empty-search"
    emptySearchContentDiv.innerHTML = `
      <span class="icon icon-search-dark product-results-empty-search-image"></span>
      <p class="product-results-empty-search-message">No matching products found</p>
    `

    this.emptySearchContent = emptySearchContentDiv;
    return emptySearchContentDiv;
  }

  openDropdown() {
    if (this.searchValue === "") {
      this.setDefaults();
    }

    this.addHighlight();
    this.updateVisibility();
    this.toggleLoadingOverlay(true, true);
  }

  closeDropdown() {
    this.removeHighlight();
    this.searchBrandsTarget.classList.add("hidden");
    this.searchProductTypesTarget.classList.add("hidden");
    this.searchTextContainerTarget.classList.add("hidden");
    this.toggleLoadingOverlay(false);
  }

  // Toggle visibility of search button if tags have been selected
  toggleSearchButton() {
    if (this.selectedTags.length > 0) {
      this.searchButtonContainerTarget.classList.remove("hidden");
      return;
    }

    this.searchButtonContainerTarget.classList.add("hidden");
  }

  // Close dropdown if user clicks outside of search container and dropdown
  clickOutsideSearch(event) {
    event.stopPropagation();
    hideOnClickOutside(this.searchBrandsTarget, "hidden", "add");
    hideOnClickOutside(this.searchProductTypesTarget, "hidden", "add");
    hideOnClickOutside(this.searchTextContainerTarget, "hidden", "add");
    hideOnClickOutside(this.searchbarTarget, "highlight", "remove");
    hideOnClickOutside(this.searchResultsTarget, "loading", "remove", this.productResults);
  }

  addHighlight() {
    this.searchbarTarget.classList.add("highlight");
  }

  removeHighlight() {
    this.searchbarTarget.classList.remove("highlight");
  }

  get searchValue() {
    return this.inputTarget.value;
  }

  get apiUrl() {
    return this.data.get("apiUrl");
  }

  get placeholderImage() {
    return this.data.get("placeholderImage");
  }

  get productDetailsUrl() {
    return this.data.get("productDetailsUrl");
  }
}
