import { Controller } from "stimulus";
import moment from "moment";
import { eventDispatcher} from "../js/event_dispatcher";
import { fetchHTML, findTimeZone } from "../js/helper_functions";

export default class extends Controller {
  static targets = [
    "showFilterChevron"
  ]

  initialize() {
    this.practitionerResults = document.getElementById("practitioner-results");
    this.filters = document.querySelector(".listings-filter-options");
    this.filterTags = [];
    this.attendanceModes = [];
    this.date = "";
    this.time = "";
    this.acceptsPromotions = false;
    this.bulkBullable = false;
    this.malePreference = false;
    this.femalePreference = false;
    this.genderPreference = this.profilePreference || "";

    

    this.configureDate();

    // Add filter tags from URL if present
    const url = new URL(window.location.href);
    if (url && (url.searchParams.has("date") || url.searchParams.has("time"))) {
      this.addDateTimeFilterTag(true);
    }

    this.addAttendanceModeTags(true);
    this.addPromotionsTag(true);
    this.addBulkBillableTag(true);
    this.addGenderTag(true);
    this.updateFilterTags();
  }

  connect() {
    window.addEventListener('resize', this.toggleFilterVisibility);
    document.addEventListener('attendance_mode_filter:updated', this.updateAttendanceModes);
    document.addEventListener('calendar_filter:updated', this.updateDateTime);
    document.addEventListener('other_filter:promotions_updated', this.togglePromotions);
    document.addEventListener('other_filter:bulk_billable_updated', this.toggleBulkBilling);
    document.addEventListener('other_filter:gender_preference_updated', this.toggleGenderPreference);
  }

  disconnect() {
    window.removeEventListener('resize', this.toggleFilterVisibility);
    document.removeEventListener('attendance_mode_filter:updated', this.updateAttendanceModes);
    document.removeEventListener('calendar_filter:updated', this.updateDateTime);
    document.removeEventListener('other_filter:promotions_updated', this.togglePromotions);
    document.removeEventListener('other_filter:bulk_billable_updated', this.toggleBulkBilling);
    document.removeEventListener('other_filter:gender_preference_updated', this.toggleGenderPreference);
  }

  updateAttendanceModes = (e) => {
    this.attendanceModes = e.detail.attendance_modes;
    this.resetFilterTags();
    this.filterAttendanceMode();
  }

  updateDateTime = (e) => {
    const { date, time } = e.detail;
    this.date = date;
    this.time = time;
    this.applyDateFilter();
    this.resetFilterTags();
  }

  togglePromotions = e => {
    this.acceptsPromotions = e.detail.accepts_promotions;
    this.resetFilterTags();
    this.filterPromotions();
  }

  toggleBulkBilling = e => {
    this.bulkBillable = e.detail.bulk_billable;
    this.resetFilterTags();
    this.filterBulkBillable();
  }

  toggleGenderPreference = e => {
    this.genderPreference = e.detail.gender;
    this.resetFilterTags();
    this.filterGender();
  }

  configureDate() {
    moment.updateLocale('en', {
      calendar : {
        lastDay : '[Yesterday]',
        sameDay : '[Today]',
        nextDay : '[Tomorrow]',
        lastWeek : '[Last] dddd',
        nextWeek : '[Next] dddd',
        sameElse : 'dddd D MMM'
      }
    });
  }

  toggleFilterVisibility = (e) => {
    if (window.innerWidth > 800) {
      this.element.classList.remove("open")
    }
  }

  // When accept is clicked
  applyDateFilter = () => {
    let url = new URL(this.practitionersUrl);
    if (!url) return;
    url.search = window.location.search;
    if (!url.searchParams.get("tz")) this.appendTimeZone(url);
    url.searchParams.append("partial", "true");
    url.searchParams.set("date", this.date);

    if (this.time) {
      url.searchParams.set("time", this.time);
    } else if (url.searchParams.has("time")) {
      url.searchParams.delete("time");
    }
    this.updatePractitioners(url);
  }

  appendTimeZone = url => {
    const timezone = findTimeZone();
    if (timezone) url.searchParams.append("tz", timezone);
    return url;
  }

  addDateTimeFilterTag = (fromUrl = false) => {
    let date, time;
    if (fromUrl) {
      let url = new URL(window.location.href);
      if (!url) return;
      let urlDate = url.searchParams.has("date") ? url.searchParams.get("date") : "";
      this.date = urlDate;
      date = moment(urlDate).calendar();
      this.time = url.searchParams.has("time") ? url.searchParams.get("time") : "";
    } else {
      date = moment(this.date).calendar();
    }

    // Check if date/time filter already applied. If so, remove before applying another.
    let dateTagAdded = this.filterTags.some(tag => tag.match(/date/));
    if (dateTagAdded) {
      let dateTag = this.filterTags.find(tag => tag.match(/date/));
      let dateTagIndex = this.filterTags.indexOf(dateTag);
      this.filterTags.splice(dateTagIndex, 1);
    }
    const tagText = `${date} ${this.time && moment(this.time, [moment.ISO_8601, 'HH:mm']).format("h:mmA")}`.trim();
    this.filterTags.push(`<li class="date" data-action="click->practitioner-filter#removeDateFilter">${tagText}</li>`);
  }

  addAttendanceModeTags = (fromUrl = false) => {
    let url = new URL(window.location.href);
    if (fromUrl) {
      this.attendanceModes = url.searchParams.has("attendance_modes[]") ? url.searchParams.getAll("attendance_modes[]") : [];
    }

    this.attendanceModes.forEach(attendanceMode => {
      this.filterTags.push(`<li class="filter-tag" data-value="${attendanceMode}" data-action="click->practitioner-filter#removeAttendanceModeFilter">${attendanceMode}</li>`);
    })
  }

  addPromotionsTag = (fromUrl = false) => {
    if (fromUrl) {
        let url = new URL(window.location.href);
        if (!url) return;
        let hasPromotions = url.searchParams.has("promotions") ? url.searchParams.get("promotions") : false;

        if (hasPromotions == "true") {
          this.acceptsPromotions = true;
        }
    }

    if (this.acceptsPromotions) {
      this.filterTags.push(`<li class="filter-tag filter-tag-promotions" data-value="promotions" data-action="click->practitioner-filter#removePromotionsFilter">Accepts Promotions</li>`);
    }
  }

  addBulkBillableTag = (fromUrl = false) => {
    if (fromUrl) {
      let url = new URL(window.location.href);
      if (!url) return;
      let bulkBillable = url.searchParams.has("accepts_bulk_billing") ? url.searchParams.get("accepts_bulk_billing") : false;
      if (bulkBillable == "true") {
        this.bulkBillable = true;
      }
    }

    if (this.bulkBillable) {
      this.filterTags.push(`<li class="filter-tag filter-tag-bulkbillable" data-value="bulkbillable" data-action="click->practitioner-filter#removeBulkBillingFilter">Medicare claimable</li>`);
    }
  }

  addGenderTag = (fromUrl = false) => {
    if (fromUrl) {
      console.log(fromUrl)
      let url = new URL(window.location.href);
      if (!url) return;
      let male = url.searchParams.has("gender") ? url.searchParams.get("gender") : false;
      if (male) {
        this.malePreference = true;
      }
    }

    if (this.genderPreference==="male") {
      this.filterTags.push(`<li class="filter-tag filter-tag-male" data-value="male" data-action="click->practitioner-filter#removeGenderFilter">Male Practitioner</li>`);
    }

    if (this.genderPreference==="female") {
      this.filterTags.push(`<li class="filter-tag filter-tag-female" data-value="female" data-action="click->practitioner-filter#removeGenderFilter">Female Practitioner</li>`);
    }
  }

  resetFilterTags = () => {
    this.filterTags = [];
    if (this.date) this.addDateTimeFilterTag();
    this.addAttendanceModeTags();
    this.addPromotionsTag();
    this.addBulkBillableTag();
    this.addGenderTag();
    this.updateFilterTags();
  }

  removeFilterTag = event => {
    let tag = event.target;
    let i = this.filterTags.indexOf(tag.outerHTML);
    this.filterTags.splice(i, 1)[0];
    this.updateFilterTags();
  }

  removeDateFilter = event => {
    let url = new URL(window.location.href);
    if (!url) return;
    this.removeFilterTag(event);
    this.date = "";
    this.time = "";
    url.searchParams.delete("date");
    url.searchParams.delete("time");
    url.searchParams.append("partial", "true");
    this.updatePractitioners(url);
  }

  removeAttendanceModeFilter = (event) => {
    this.removeFilterTag(event);
    eventDispatcher.dispatch("filter_tag:removed", { type: "attendance_mode", value: event.target.dataset.value });
  }

  removePromotionsFilter = event => {
    let url = new URL(window.location.href);
    if (!url) return;
    this.removeFilterTag(event);
    eventDispatcher.dispatch("filter_tag:removed", { type: "other_filter", value: event.target.dataset.value });
    url.searchParams.delete("promotions");
    url.searchParams.append("partial", "true");
    this.updatePractitioners(url);
  }

  removeBulkBillingFilter = event => {
    let url = new URL(window.location.href);
    if (!url) return;
    this.removeFilterTag(event);
    eventDispatcher.dispatch("filter_tag:removed", { type: "other_filter", value: event.target.dataset.value });
    url.searchParams.delete("accepts_bulk_billing");
    url.searchParams.append("partial", "true");
    this.updatePractitioners(url);
  }

  removeGenderFilter = event => {
    let url = new URL(window.location.href);
    if (!url) return;
    this.removeFilterTag(event);
    eventDispatcher.dispatch("filter_tag:removed", { type: "other_filter", value: event.target.dataset.value });
    url.searchParams.delete("gender");
    url.searchParams.append("partial", "true");
    this.updatePractitioners(url);
  }

  updatePractitioners(url) {
    this.fetchPractitioners(url)
      .then(html => {
        url.searchParams.delete("partial");
        this.practitionerResults.innerHTML = html;
        window.history.pushState({}, "Search", url);
      })
      .catch(e => Rollbar.error(e))
  }

  fetchPractitioners = (url) => {
    return fetchHTML(url);
  }

  updateFilterTags = () => {
    // Stimulus targets filterCount, filterTags and filterTagsContainer did not work here
    const filterCount = document.querySelector(".filter-count")
    const filterTags = document.querySelector(".filter-tags");
    const filterTagsContainer = document.querySelector(".filter-tags-container");
    filterTags.innerHTML = this.filterTags.join('');
    if (this.filterTags.length === 0) {
      filterTagsContainer.classList.add("hidden");
      filterCount.textContent = "";
    } else {
      filterTagsContainer.classList.remove("hidden");
      filterCount.textContent = `(${this.filterTags.length})`;
    }
  }

  toggleFilters = (event) => {
    this.element.classList.toggle("open");
  }

  closeFilters = () => {
    this.element.classList.remove("open");
  }

  filterAttendanceMode = () => {
    let url = new URL(this.practitionersUrl);
    if (!url) return;
    url.search = window.location.search;
    url.searchParams.append("partial", "true");

    // Clear old attendance modes
    url.searchParams.delete("attendance_modes[]");

    this.attendanceModes.forEach(mode => {
      url.searchParams.append("attendance_modes[]", mode)
    })

    this.updatePractitioners(url);
  }

  filterPromotions = () => {
    let url = new URL(this.practitionersUrl);
    if (!url) return;

    url.search = window.location.search;
    url.searchParams.append("partial", "true");

    if (this.acceptsPromotions) {
      url.searchParams.set("promotions", "true");
    } else {
      url.searchParams.delete("promotions");
    }
    this.updatePractitioners(url);
  }

  filterBulkBillable = () => {
    let url = new URL(this.practitionersUrl);
    if (!url) return;

    url.search = window.location.search;
    url.searchParams.append("partial", "true");
    if (this.bulkBillable) {
      url.searchParams.set("accepts_bulk_billing", "true");
    } else {
      url.searchParams.delete("accepts_bulk_billing");
    }
    this.updatePractitioners(url);
  }

  filterGender = () => {
    let url = new URL(this.practitionersUrl);
    if (!url) return;

    url.search = window.location.search;
    url.searchParams.append("partial", "true");
    if (this.genderPreference === "male") {
      url.searchParams.set("gender", "male");
    } 
    else if(this.genderPreference === "female") {
      url.searchParams.set("gender", "female");
    } else {
      url.searchParams.delete("gender");
    }
    this.updatePractitioners(url);
  }

  get practitionersUrl() {
    return this.data.get("practitionersUrl");
  }

  get profilePreference() {
    return JSON.parse(this.data.get("profilePreference"));
  }
}
