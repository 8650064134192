// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import "objectFitPolyfill";
import "element-closest/browser";

import jQuery from 'jquery';
import { Turbo } from '@hotwired/turbo-rails';
import RailsUJS from 'rails-ujs';
import 'tippy.js/dist/tippy.css';
import "flatpickr/dist/flatpickr.min.css";
import './platforma-template/index';

import "flatpickr/dist/flatpickr.min.css";

import RollbarClient from "../shared/js/rollbar";

const rollbar = new RollbarClient();
rollbar.connect();

require.context('./img', true, /\.(svg|png|jpg)$/im);
require.context('./../shared/img', true, /\.(svg|png|jpg)$/im);

// StimulusJS
import { Application as Stimulus } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
const stimulus = Stimulus.start();
const controllers = require.context("./js/controllers", true, /\.js$/);
stimulus.load(definitionsFromContext(controllers));

const sharedControllers = require.context("../shared/controllers", true, /\.js$/);
stimulus.load(definitionsFromContext(sharedControllers));

if (window.hasOwnProperty("Rollbar")) {
  const defaultErrorHandler = stimulus.handleError;
  const rollbarErrorHandler = (error, message, detail) => {
    defaultErrorHandler(error, message, detail);
    window.Rollbar.error(error);
  };

  stimulus.handleError = rollbarErrorHandler;
}

import "../shared/js/turbolinks.js";
import "../shared/js/preserve_scroll.js";

import "./css/index.scss";

RailsUJS.start();
