import { Controller } from "stimulus";
import { notifyService } from "../js/notify_service";
import { fetchJSON } from '../../shared/js/helper_functions';

export default class extends Controller {
  static targets = [
    "row",
    "rowsContainer",
    "newAttachmentCategoryCheckbox",
    "addAttachmentCategoryBtn",
    "addAttachmentCategoryText",
    "dragger",
  ];

  initialize() {
    this.rowHTML = this.rowTargets[this.rowTargets.length - 1].innerHTML;
    this.active = false;
    this.currentY = 0;
    this.initialY = 0;
    this.yOffset = 0;
    this.startY = 0;
    this.startPosition = null;
    this.rowHeight = this.calculateRowHeight();
    this.rowGap = this.calculateRowGap();
    this.constraints = this.calculateConstraints();
    this.rowShiftsY = Array(this.rowTargets.length).fill(0);
    this.initialPositions = this.getPositions();
    this.currentPositions = [...this.initialPositions];
    this.newPositions = [...this.initialPositions];
  }

  connect() {
    document.addEventListener("mousedown", this.onMouseDown);
    document.addEventListener("mouseup", this.dragEnd);
    document.addEventListener("mousemove", this.drag, { passive: false });
    document.addEventListener("touchstart", this.onTouchStart);
    document.addEventListener("touchend", this.dragEnd);
    document.addEventListener("touchmove", this.drag, { passive: false });
  }

  disconnect() {
    document.removeEventListener("mousedown", this.onMouseDown);
    document.removeEventListener("mouseup", this.dragEnd);
    document.removeEventListener("mousemove", this.drag, { passive: false });
    document.removeEventListener("touchstart", this.onTouchStart);
    document.removeEventListener("touchend", this.dragEnd);
    document.removeEventListener("touchmove", this.drag, { passive: false });
  }

  calculateRowHeight = () => {
    const { height } = this.rowTargets[0].getBoundingClientRect();
    return height;
  };

  calculateRowGap = () => {
    if (this.rowTargets.length < 2) {
      return 0;
    }

    return (
      this.rowTargets[1].getBoundingClientRect().top -
      this.rowTargets[0].getBoundingClientRect().bottom
    );
  };

  calculateConstraints = () => {
    const { top, bottom } = this.rowsContainerTarget.getBoundingClientRect();
    return { minY: top, maxY: bottom - this.rowHeight };
  };


  getPositions = () => {
    return Array.from(this.rowTargets).map((row) => parseInt(row.dataset.categoryPosition));
  };

  createRow = () => {
    const rowIndex = this.rowTargets.length;
    const newRow = document.createElement("div");
    newRow.className = "field attachment-category mt-2";
    newRow.dataset.target = "attachment-categories.row";
    newRow.dataset.categoryPosition = rowIndex;
    newRow.id = "";
    newRow.innerHTML = this.replaceHtmlPositions(this.rowHTML, rowIndex);
    newRow.childNodes[1].setAttribute("value", "");
    const nameInput = newRow.querySelector(".sidebar-modal-input");
    nameInput.value = "";
    nameInput.setAttribute("value", "");
    nameInput.classList.remove("error");
    this.rowsContainerTarget.appendChild(newRow);

    // Need to calculate row gap when going from 1 row to 2 rows.
    if (rowIndex === 1) {
      this.rowGap = this.calculateRowGap();
    }
    this.constraints = this.calculateConstraints();
    this.initialPositions.push(rowIndex);
    this.currentPositions.push(rowIndex);
    this.newPositions.push(rowIndex);
  };



  deleteCategory = (event) => {
    const categoryRow = event.currentTarget.closest(".attachment-category");
    const deletedPosition = parseInt(categoryRow.dataset.categoryPosition);
    const updateRows = () => {
      this.rowsContainerTarget.removeChild(categoryRow);
      this.constraints = this.calculateConstraints();
      this.initialPositions.pop();
      this.currentPositions.splice(this.currentPositions.indexOf(deletedPosition), 1);
      this.currentPositions = this.currentPositions.map((position) =>
        position > deletedPosition ? position - 1 : position
      );
      this.rowShiftsY = this.calculateRowShifts(this.currentPositions);
      this.rowTargets.forEach((row, i) => {
        this.setTranslate(this.rowShiftsY[i], row);
        this.updateRowPosition(row, this.currentPositions[i]);
      });
    };

    const id = categoryRow.id;
    // Category hasn't been saved to db yet. No need to notify backend.
    if (!id) {
      updateRows();
      return;
    }

    fetchJSON(this.destroyQuestionUrl, {
      method: "PATCH",
      data: {
        id: id,
      }
    })
    .then((response) => {
      if (response.success) {
        updateRows();
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete("filter");
        window.history.pushState({}, "Remove Filter", newUrl);
        const attachmentsTable = document.getElementById("document-library-table");
        // attachmentsTable.innerHTML = html;
        // const categoryItem = attachmentsTable.querySelector(`[data-attachment-category-id="${id}"]`);
        // if(categoryItem === null) {
        //   notifyService.notify("info", "Attachment category removed successfully");
        // } else {
        //   notifyService.notify("warning", "Attachment category could not be removed");
        // }
      }
    })
  };

  replaceHtmlPositions = (html, position) => {
    return html
      .replace(/\[\d+\]/g, `[${position}]`)
      .replace(/(row-position-input" type="hidden" value=")\d+/g, `$1${position}`)
      .replace(/_\d+_/g, `_${position}_`);
  };

  onMouseDown = (event) => {
    // Left mouse button only.
    if (event.button !== 0) {
      return;
    }
    this.dragStart(event);
  };

  onTouchStart = (event) => {
    // Single touch only.
    if (event.touches.length > 1) {
      return;
    }
    this.dragStart(event);
  };

  // Minimise video drag listeners
  dragStart = (event) => {
    if (!event.target.classList.contains("draggable-handle")) {
      return;
    }

    this.removeErrors();

    if (event.type === "touchstart") {
      this.initialY = event.touches[0].clientY;
    } else {
      this.initialY = event.clientY;
    }

    this.active = true;
    this.draggedRow = event.target.closest(".attachment-category");
    const { y: startY } = this.draggedRow.getBoundingClientRect();
    this.startY = startY;
    this.yOffset = startY - this.draggedRow.offsetTop;
    this.draggedRow.classList.add("dragging");
    document.body.classList.add("dragging");
    this.startPosition = parseInt(this.draggedRow.dataset.categoryPosition);
  };
  
  addQuestion = (data) => {
      fetchJSON(this.updateQuestionUrl, {
        method: "PATCH",
        data: {
          questions: data,
          id: this.formId
        }
      })
      .then(response => {
        if (!response.success) throw "Bulk Delete Failed";
        window.location = response.redirectUrl;
        location.reload();
      })
  }

  dragEnd = () => {
    if (!this.active) {
      return;
    }

    this.draggedRow.classList.remove("dragging");
    document.body.classList.remove("dragging");
    this.currentPositions = this.newPositions;
    this.rowTargets.forEach((row, i) => {
      if (row === this.draggedRow) {
        this.setTranslate(this.rowShiftsY[i], row);
      }
      this.updateRowPosition(row, this.currentPositions[i]);
    });

    this.active = false;
    this.draggedRow = null;
    this.currentY = 0;
    this.initialY = 0;
    this.yOffset = 0;
    this.startY = 0;
    this.deltaY = 0;
    this.startPosition = null;

    const ids = Array.from(this.rowTargets).map((row) => row.dataset.categoryId);
    const positions = this.newPositions

    let updatePositionData = ids.map(function (x, i) { 
      return [x, positions[i]] 
    });

    this.addQuestion(updatePositionData)
  };



  drag = (event) => {
    if (!this.active || !this.draggedRow) return;

    event.preventDefault();

    if (event.type === "touchmove") {
      this.deltaY = event.touches[0].clientY - this.initialY;
    } else {
      this.deltaY = event.clientY - this.initialY;
    }

    const { minY, maxY } = this.constraints;

    if (this.startY + this.deltaY < minY) {
      this.deltaY = minY - this.startY;
    }
    if (this.startY + this.deltaY > maxY) {
      this.deltaY = maxY - this.startY;
    }

    this.currentY = this.yOffset + this.deltaY;

    this.setTranslate(this.currentY, this.draggedRow);

    const deltaPosition = Math.round(this.deltaY / this.rowTotalHeight);
    this.newPositions = this.getNewPositions(deltaPosition, this.startPosition);
    this.rowShiftsY = this.calculateRowShifts(this.newPositions);
    this.rowTargets.forEach((row, i) => {
      if (row !== this.draggedRow) {
        this.setTranslate(this.rowShiftsY[i], row);
      }
    });
  };

  setTranslate = (yPos, el) => {
    el.style.transform = `translateY(${yPos}px)`;
  };

  getNewPositions = (deltaPosition, startPosition) => {
    if (deltaPosition === 0) {
      return this.currentPositions;
    }

    const endPosition = startPosition + deltaPosition;
    return this.currentPositions.map((position) => {
      if (position == startPosition) {
        return startPosition + deltaPosition;
      }
      if (deltaPosition > 0 && position > startPosition && position <= endPosition) {
        return position - 1;
      }
      if (deltaPosition < 0 && position < startPosition && position >= endPosition) {
        return position + 1;
      }
      return position;
    });
  };

  calculateRowShifts = (newPositions) => {
    return newPositions.map((newPosition, i) => {
      const deltaPosition = newPosition - this.initialPositions[i];
      return deltaPosition * this.rowTotalHeight;
    });
  };

  updateRowPosition = (row, position) => {
    row.dataset.categoryPosition = position;
    row.innerHTML = this.replaceHtmlPositions(row.innerHTML, position);
  };

  updateInputValue = (e) => {
    e.currentTarget.setAttribute("value", e.currentTarget.value);
  };

  removeErrors = () => {
    const errorSpans = this.element.querySelectorAll('span.error');
    if (errorSpans) {
      errorSpans.forEach(span => span.remove());
    }

    const errorInputs = this.element.querySelectorAll('input.error');
    if (errorInputs) {
      errorInputs.forEach(input => input.classList.remove('error'));
    }

    this.constraints = this.calculateConstraints();
  }

  cancelDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  get rowTotalHeight() {
    return this.rowHeight + this.rowGap;
  }

  get destroyQuestionUrl() {
    return this.data.get("destroyUrl");
  }

  get updateQuestionUrl() {
    return this.data.get("updateUrl");
  }

  get formId() {
    return this.data.get("formId");
  }
}
