class PreserveScroll {
  constructor() {
    this.preserveScroll = false;

    document.addEventListener("turbo:render", () => {
      this.onRender();
    })
  }

  preserve() {
    this.preserveScroll = true;
    this.scrollPosition = document.documentElement.scrollTop;
  }

  onRender() {
    if (this.preserveScroll) {
      document.documentElement.scrollTop = this.scrollPosition;
      this.preserveScroll = false;
    }
  }
}

export let preserveScroll = new PreserveScroll();
