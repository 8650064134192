import { Controller } from "stimulus";
import tippy from "tippy.js";
import { eventDispatcher} from "../js/event_dispatcher";

export default class extends Controller {
  static targets = [
    "online",
    "phone",
    "clinic"
  ]

  initialize() {
    this.attendanceModes = [];

    this.setAttendanceModesFromUrl();
    this.configureTippy();
  }

  connect() {
    document.addEventListener('keyup', this.handleEscapeKeyPress);
    document.addEventListener('filter_tag:removed', this.handleTagRemoval);
  }

  disconnect() {
    document.removeEventListener('keyup', this.handleEscapeKeyPress);
  }

  handleEscapeKeyPress = (e) => {
    if (e.key === "Escape") {
      this.hideTippy();
    }
  }

  handleTagRemoval = (e) => {
    const { type, value } = e.detail;
    if (type !== "attendance_mode") return;
    this.attendanceModes.splice(this.attendanceModes.indexOf(value), 1);
    this[`${value}Target`].checked = false;
    eventDispatcher.dispatch("attendance_mode_filter:updated", { attendance_modes: this.attendanceModes });
  }

  updateAttendaceModes = (e) => {
    if (e.target.checked) {
      this.attendanceModes.push(e.target.value);
    } else {
      this.attendanceModes.splice(this.attendanceModes.indexOf(e.target.value), 1);
    }
    eventDispatcher.dispatch("attendance_mode_filter:updated", { attendance_modes: this.attendanceModes });
  }

  // Checking attendance modes (appointment types) based on url params
  setAttendanceModesFromUrl = () => {
    let url = new URL(window.location.href);
    if (!url || !url.searchParams.has("attendance_modes[]")) return;

    this.resetCheckboxes();
    this.attendanceModes = [];

    const selectedAttendanceModes = url.searchParams.getAll("attendance_modes[]");

    if (selectedAttendanceModes.indexOf("online") !== -1) {
      this.onlineTarget.checked = true;
      this.attendanceModes.push("online");
    }
    if (selectedAttendanceModes.indexOf("phone") !== -1) {
      this.phoneTarget.checked = true;
      this.attendanceModes.push("phone");
    }
    if (selectedAttendanceModes.indexOf("clinic") !== -1) {
      this.clinicTarget.checked = true;
      this.attendanceModes.push("clinic");
    }
  }

  resetCheckboxes = () => {
    this.onlineTarget.checked = false;
    this.phoneTarget.checked = false;
    this.clinicTarget.checked = false;
  }

  configureTippy = () => {
    this.element.classList.remove("hidden");
    const attendanceMode = document.querySelector(".attendance-mode");
    const chevron = attendanceMode.childNodes[1];

    tippy.setDefaultProps({
      duration: 0,
      arrow: true,
      theme: "light-border"
    });

    tippy('.attendance-mode', {
      content: this.element,
      interactive: true,
      trigger: 'click',
      placement: 'bottom-start',
      maxWidth: '',
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            enabled: true
          },
        ],
      },
      zIndex: 2,
      onShown() {
        chevron.classList.add("open");
        attendanceMode.classList.add("highlight");
      },
      onHidden() {
        chevron.classList.remove("open");
        attendanceMode.classList.remove("highlight");
      }
    });
  }

  hideTippy() {
    const popper = document.querySelector('.tippy-popper')
    const instance = popper._tippy
    instance.hide();
  }
}
