import { Controller } from "stimulus"
import tippy from "tippy.js";

export default class extends Controller {
  initialize(){
    tippy.setDefaultProps({
      duration: 0,
      arrow: true,
      placement: "bottom-end",
      theme: "grey"
    });

    tippy(this.element.querySelectorAll(".m-listing-card-promo-icon"));
    tippy(this.element.querySelectorAll(".video-conference-icon"));
    tippy(this.element.querySelectorAll(".phone-icon"));
    tippy(this.element.querySelectorAll(".clinic-icon"));
    tippy(this.element.querySelectorAll(".extra-professions"));
    tippy(this.element.querySelectorAll(".m-listing-card-medicare-icon"));
  }

  handleCardClick() {
    if (this.data.get("invitation")) {
      return;
    }
    window.location = this.path;
  }

  allowLinkClick(e) {
    e.stopPropagation();
  }

  get path() {
    return this.data.get("path");
  }
}
