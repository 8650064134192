import { Controller } from "stimulus";
import tippy from "tippy.js";
import { modalService } from "../../application/js/modal_service.js"

export default class extends Controller {
  static targets = ["avatar"];

  initialize() {
    tippy.setDefaultProps({
      duration: 0,
      arrow: true,
      placement: "bottom-end",
      theme: "grey"
    })

    this.avatarTargets.forEach(avatar => tippy(avatar));
  }

  openInviteModal = e => {
    e.stopPropagation();
    e.preventDefault();

    modalService.fetchAndOpen(e.target.href);
  }
}
