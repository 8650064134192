import rollbar from "rollbar";

export default class RollbarClient {
  connect() {
    const rollbarConfig = {
      enabled: process.env.ROLLBAR_ENV === "staging" || process.env.ROLLBAR_ENV === "production",
      accessToken: "0d5919ba8bb7433081d6df095a5e2a40",
      captureUncaught: true,
      captureUnhandledRejections: true,
      logLevel: "error",
      reportLevel: "error",
      hostWhiteList: ["healthbank.io", "staging.healthbank.io", "dev.healthbank.io"],
      hostBlackList: ["intercomcdn.com"],
      payload: {
        environment: process.env.ROLLBAR_ENV
      }
    };

    window.Rollbar = window.Rollbar || new rollbar(rollbarConfig);
  }
}
