import { Controller } from "stimulus";
import tippy from "tippy.js";
import { fetchHTML } from "../js/helper_functions";

// Ajax submit the given path using the given method.
// Replace the current controller element with the HTML fragment
// returned by the server.
export default class extends Controller {
  initialize() {
    tippy.setDefaultProps({
      duration: 0,
      arrow: true,
      placement: "bottom-end",
      theme: "grey"
    })

    tippy(this.element.querySelectorAll('.shortlist-link'));
  }

  addFavourite(e) {
    this.toggleFavourite(e, true);
  }

  removeFavourite(e) {
    this.toggleFavourite(e);
  }

  toggleFavourite(e, showAlert = false) {
    e.stopPropagation();

    fetchHTML(this.path, { method: this.method })
      .then(data => {
        this.element.outerHTML = data;
        if (showAlert) this.showAlert(e);
      })
      .catch(err => Rollbar.error(err));

    e.preventDefault();
  }

  addFavouriteDashboard = (e, showAlert = true) => {
    e.stopPropagation();

    fetchHTML(this.path, { method: this.method })
      .then(data => {
        if (showAlert) this.showAlert(e);
        this.hideTippy();
      })
      .catch(err => Rollbar.error(err));

    e.preventDefault();
  }

  removeFavouriteDashboard = e => {
    e.stopPropagation();

    fetchHTML(this.path, { method: this.method })
      .then(data => {
        const selector = `.favourite-practitioner[data-practitioner-id="${this.practitionerId}"]`;
        const card = document.querySelector(selector);
        card.remove();
        this.hideTippy();
        this.toggleAddMoreFavouritesLink();

        let isEmpty = this.checkFavouritesIsEmpty();
        if (isEmpty) {
          this.togglePractitionerBlocks();
        }
      })
      .catch(err => Rollbar.error(err));

    e.preventDefault();
  };

  checkFavouritesIsEmpty = () => {
    const favouritePracs = document.querySelector(".favourite-practitioners-block");
    const cards = favouritePracs.querySelectorAll(".dashboard-client-card");
    let isEmpty = false;

    if (cards.length === 0) {
      isEmpty = true;
    }

    return isEmpty;
  }

  togglePractitionerBlocks = () => {
    const favouritePactitionerTitle = document.querySelector(".favourite-practitioners-title")
    const favouritePractitioners = document.querySelector(".favourite-practitioners-block");
    const recommendedPractitioners = document.querySelector(".recommended-practitioners-block")

    favouritePactitionerTitle.innerHTML = "Available Practitioners";
    favouritePractitioners.classList.add("hidden!");
    recommendedPractitioners.classList.remove("hidden!");
  }

  toggleAddMoreFavouritesLink = () => {
    const favouritePractitionersBlock = document.querySelector(".favourite-practitioners-block");
    const favouritePractitioners = favouritePractitionersBlock.querySelectorAll(".dashboard-client-card");
    const addMoreFavouritesLink = favouritePractitionersBlock.querySelector(".fav-prac-downstate-link");

    let showLink = favouritePractitioners.length < 3 ? false : true;
    addMoreFavouritesLink.classList.toggle("hidden!", showLink);
  };

  showAlert(e) {
    e.stopPropagation();
    e.preventDefault();
    const notificationAlert = document.getElementById("notification-alert");
    notificationAlert.classList.add("visible");

    setTimeout(function() {
      notificationAlert.classList.remove("visible");
    }, 4000);
  }

  hideTippy() {
    const popper = document.querySelector(".tippy-popper");
    const instance = popper._tippy;
    instance.destroy();
  }

  get path() {
    return this.data.get("path");
  }

  get method() {
    return this.data.get("method");
  }

  get practitionerId() {
    return this.data.get("practitioner-id");
  }
}
