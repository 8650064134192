import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "plan", 
    "checkbox" 
  ]
  
  select = event => {
    // remove styling from plans
    this.planTargets.forEach(plan =>  plan.classList.remove("is-selected"));
    this.checkboxTargets.forEach(checkbox =>  checkbox.checked = false);

    // add styling to selected plans and check the plan's checkbox
    let checkbox = event.currentTarget.querySelector('.plan-checkbox');
    checkbox.checked = true;
    event.currentTarget.classList.add("is-selected");
  }

  back = () => {
    window.history.back();
  }
}