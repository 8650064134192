class EventDispatcher {
  constructor() {
  }

  dispatch(name, args = null) {
    document.dispatchEvent(new CustomEvent(name, { detail: args }));
  }

}

export let eventDispatcher = new EventDispatcher();
