import { Controller } from "stimulus";
import { fetchJSON } from "../js/helper_functions";

export default class extends Controller {
  static targets = [
    "infoMessage",
    "requestedMessage",
    "button"
  ]

  sendRequest = e => {
    this.buttonTarget.setAttribute("disabled", true);

    const data = {
      feature_name: this.name
    };
    fetchJSON(this.url, { method: "POST", data })
      .then(() => this.toggleMessages(true))
      .catch(e => Rollbar.error(e))
  }

  toggleMessages = (requested = true) => {
    if (this.hasInfoMessageTarget) {
      this.infoMessageTarget.classList.toggle("hidden", requested);
    }
    if (this.hasRequestedMessageTarget) {
      this.requestedMessageTarget.classList.toggle("hidden", !requested);
    }
  }

  get name() {
    return this.data.get("name");
  }

  get url() {
    return this.data.get("url");
  }
}
