import * as iconUrl from "./../img/map_icon.png";
import { Controller } from "stimulus";
import { loadScript } from "./../js/helper_functions";
import "../css/_map.scss";

export default class extends Controller {
  initialize() {
    if (document.querySelector('script[src="https://maps.googleapis.com/maps-api-v3/api/js/37/6/map.js"]')) {
      this.initMap();
    } else {
      loadScript(this.data.get("scriptSrc"), this.initMap);
    }
  }

  initMap = () => {
    const mapDiv = document.getElementById('map');
    const lat = parseFloat(mapDiv.dataset.lat)
    const lng = parseFloat(mapDiv.dataset.lng)
    const coords = {lat, lng};

    let map = new google.maps.Map(document.getElementById('map'), {
      center: coords,
      zoom: 14,
      maxZoom: 15,
      mapTypeControl: false,
      streetViewControl: false,
      styles
    });

    const image = iconUrl;
    let marker = new google.maps.Marker({
      position: coords,
      map: map,
      title: "Clinic",
      icon: image
    });
  }
}

const styles = [
  {
    "featureType": "all",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#00fdff"
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#35404e"
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "saturation": "0"
      }
    ]
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -70
      }
    ]
  },
  {
    "featureType": 'road.highway',
    "elementType": 'geometry',
    "stylers": [
      {
        "color": '#e3e3e3'
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "saturation": -60
      }
    ]
  }
]
