import { hideOnClickOutside, fetchJSON, fetchHTML }  from "./../js/helper_functions"
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "searchbar",
    "searchbarTop",
    "searchProfessions",
    "searchSpecialties",
    "matchedProfessions",
    "matchedProfessionsList",
    "matchedSpecialties",
    "matchedSpecialtiesList",
    "selectedTags",
    "searchButtonContainer",
    "searchTextContainer",
    "searchText",
  ];

  initialize() {
    this.practitionerResults = document.getElementById("practitioner-results");

    this.professions = this.allProfessions;
    this.specialties = [];

    // Matched professions and specialties based on key input
    this.professionMatches = [];
    this.specialtyMatches = [];

    // Selected professions and specialties based on click
    // These data are sent through params to backend
    this.selectedProfessions = [];
    this.selectedSpecialties = [];

    // Free text search params
    this.textSearchParams = [];

    // Tags selected on click.
    // These hold same data as above, but in HTML format for views only
    this.selectedTags = [];

    this.selectTagFromUrlParams("professions[]", this.selectProfession);
    this.selectTagFromUrlParams("query[]", this.selectSearchText);

    // Pull this.specialties from backend
    this.updateSpecialties(true);
  }

  connect() {
    document.addEventListener('keyup', this.handleEscapeKeyPress);
    document.addEventListener('keyup', this.watchInput);
    window.addEventListener('resize', this.setSearchContainerHeight);
  }

  disconnect() {
    document.removeEventListener('keyup', this.handleEscapeKeyPress);
    document.removeEventListener('keyup', this.watchInput);
    window.removeEventListener('resize', this.setSearchContainerHeight);
  }

  handleEscapeKeyPress = e => {
    if (e.key === "Escape") {
      this.closeDropdown();
    }
  }

  setSearchContainerHeight = () => {
    this.element.style.height = `${this.searchbarTopTarget.clientHeight + 2}px`;
  }

  watchInput = () => {
    this.searchTextTarget.textContent = `Search for '${this.inputTarget.value.trim()}'`;
  }

  selectTagFromUrlParams(paramName, selectFunction) {
    const url = new URL(window.location.href);
    const params = url.searchParams.getAll(paramName);
    if (params.length > 0) {
      params.forEach(selectFunction);
      this.updateSelectedTags();
      this.toggleSearchButton();
      this.setSearchContainerHeight();
    }
  }

  // Match professions and specialties to user input string
  updateSearchResults() {
    let trimmedSearchValue = this.searchValue.trim();
    if (trimmedSearchValue.toLowerCase() === "gp") {
      trimmedSearchValue = "general practitioner";
    }
    let searchSubString = new RegExp(trimmedSearchValue, 'i');

    this.professionMatches = this.professions
      .filter(profession => {
        return (profession.match(searchSubString));
      })
      .sort((x, y) => {
        return (x.match(searchSubString).index - y.match(searchSubString).index);
      });

    this.specialtyMatches = this.specialties
      .filter(specialty => {
        return (specialty.match(searchSubString));
      })
      .sort((x, y) => {
        return (x.match(searchSubString).index - y.match(searchSubString).index);
      })

    this.showSearchResults();
    this.updateVisibility();
    this.toggleSearchButton();
  }

  // Retrieve all profession and specialty matches and show in DOM
  showSearchResults() {
    this.matchedProfessionsListTarget.innerHTML = this.professionMatches.map(profession => {
      return `<li class="profession">${profession}</li>`;
    }).join('');

    this.matchedSpecialtiesListTarget.innerHTML = this.specialtyMatches.map(specialty => {
      return `<li class="specialty">${specialty}</li>`;
    }).join('');
  }

  // Set 'results' to all professions specialties on dropdown initial click
  setDefaults() {
    this.matchedProfessionsListTarget.innerHTML = this.professions.map(profession => {
      return `<li class="profession">${profession}</li>`;
    }).join('');

    this.matchedSpecialtiesListTarget.innerHTML = this.specialties.map(specialty => {
      return `<li class="specialty">${specialty}</li>`;
    }).join('');
  }

  // Update visibility of headers and containers based on results
  updateVisibility() {
    if (!this.matchedProfessionsListTarget.hasChildNodes()) {
      this.searchProfessionsTarget.classList.add("hidden");
    } else {
      this.searchProfessionsTarget.classList.remove("hidden");
      this.addHighlight();
    }

    if (!this.matchedSpecialtiesListTarget.hasChildNodes()) {
      this.searchSpecialtiesTarget.classList.add("hidden");
    } else {
      this.searchSpecialtiesTarget.classList.remove("hidden");
      this.addHighlight();
    }

    if (this.inputTarget.value.trim() === "") {
      this.searchTextContainerTarget.classList.add("hidden");
    } else {
      this.searchTextContainerTarget.classList.remove("hidden");
      this.addHighlight();
    }

    if (!this.matchedProfessionsListTarget.hasChildNodes() && !this.matchedSpecialtiesListTarget.hasChildNodes() && this.inputTarget.value === "") {
      this.removeHighlight();
    }
  }

  // Move clicked tag from list to selected tags in search bar
  handleSelect(e) {
    let target = e.target;
    let tag = target.textContent;

    if (target.parentElement.className === "matched-professions-list") {
      this.selectProfession(tag);
    } else if (target.parentElement.className === "matched-specialties-list") {
      this.selectSpecialty(tag);
    } else if (target.parentElement.className === "search-text") {
      this.selectSearchText();
    }

    this.updateFields();
  }

  updateFields() {
    this.inputTarget.value = "";
    this.inputTarget.focus();
    this.setDefaults();
    this.updateSelectedTags();
    this.updateVisibility();
    this.toggleSearchButton();
    this.setSearchContainerHeight();
  }

  selectProfession = tag => {
    let i = this.professions.indexOf(tag);
    if (i === -1) return;
    let clickedTag = this.professions.splice(i, 1)[0];
    this.selectedProfessions.push(clickedTag);
    this.selectedTags.push(`<li class="profession">${clickedTag}</li>`);
    this.updateSpecialties();
  }

  selectSpecialty = tag => {
    let i = this.specialties.indexOf(tag);
    if (i === -1) return;
    let clickedTag = this.specialties.splice(i, 1)[0];
    this.selectedSpecialties.push(clickedTag);
    this.selectedTags.push(`<li class="specialty">${clickedTag}</li>`);
  }

  selectSearchText = tag => {
    let searchText = this.inputTarget.value.trim() || tag;
    this.textSearchParams.push(searchText);
    this.selectedTags.push(`<li class="text">${searchText}</li>`);
    this.inputTarget.value = "";
  }

  // Move clicked tag from search bar back to list
  handleDeselect(event) {
    let target = event.target;

    // Remove from data array
    this.deleteTag(target);

    // Remove from view array
    let j = this.selectedTags.indexOf(target.outerHTML);
    this.selectedTags.splice(j, 1);

    this.updateSearchResults();
    this.updateSelectedTags();
    this.setSearchContainerHeight();
  }

  // Helper method for removing tag profession or specialty from data arrays
  deleteTag(target) {
    let tag = target.innerHTML;
    let tagClass = target.className;

    if (tagClass === "profession") {
      let i = this.selectedProfessions.indexOf(tag);
      let clickedProfessionTag = this.selectedProfessions.splice(i, 1)[0];
      this.professions.push(clickedProfessionTag);
      this.professions.sort(this.sortByLowercase);
      this.updateSpecialties();
    } else if (tagClass === "specialty") {
      let i = this.selectedSpecialties.indexOf(tag);
      let clickedSpecialtyTag = this.selectedSpecialties.splice(i, 1)[0];
      this.specialties.push(clickedSpecialtyTag);
      this.specialties.sort(this.sortByLowercase);
    } else if (tagClass === "text") {
      let i = this.textSearchParams.indexOf(tag);
      this.textSearchParams.splice(i, 1)[0];
    }
  }

  sortByLowercase = (a, b) => {
    const nameA = a.toLowerCase();
    const nameB = b.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  // Delete the most recently added tag from list of selected tags with backspace
  // Handle Enter
  handleKeyDown(event) {
    if (event.keyCode === 8 && this.inputTarget.value === "" && this.selectedTags.length > 0) {
      this.selectedTags.pop();
      let target = this.selectedTagsTarget.lastChild;
      this.deleteTag(target);
      this.updateSelectedTags();
      this.setSearchContainerHeight();
    } else if (event.keyCode === 13 && this.inputTarget.value !== "") {
      this.selectSearchText();
      this.updateFields();
    } else if (event.keyCode === 13 && this.inputTarget.value === "") {
      this.handleSearchButtonClick();
      this.inputTarget.blur();
    }
  }

  // Update list of selected tags in search bar
  updateSelectedTags() {
    this.selectedTagsTarget.innerHTML = this.selectedTags.join('');

    if (this.selectedTags.length === 0) {
      this.selectedTagsTarget.classList.add("hidden");
    } else {
      this.selectedTagsTarget.classList.remove("hidden");
    }
  }

  // Retrieve list of specialties from backend
  fetchSpecialties() {
    let url = new URL(this.specialtiesUrl)
    this.selectedProfessions.forEach(name => url.searchParams.append("profession_names[]", name));
    return fetchJSON(url);
  }

  updateSpecialties(fromUrl = false) {
    this.fetchSpecialties()
      .then(data => {
        this.specialties = data.map(specialty => specialty.name);
        if (fromUrl) this.selectTagFromUrlParams("specialties[]", this.selectSpecialty);
        this.setDefaults();
      })
      .catch(err => Rollbar.error(err))
  }

  // Append search params to URL and trigger request for practitioners matching search data
  handleSearchButtonClick() {
    let url = new URL(this.practitionersUrl);

    const timezone = new URL(window.location.href).searchParams.get("tz");
    if (timezone) url.searchParams.append("tz", timezone);

    const date = new URL(window.location.href).searchParams.get("date");
    const time = new URL(window.location.href).searchParams.get("time");
    const attendanceMode = new URL(window.location.href).searchParams.get("attendance_modes[]");
    const gender = new URL(window.location.href).searchParams.get("gender");
    const acceptsBulkBilling = new URL(window.location.href).searchParams.get("accepts_bulk_billing");
    const promotions = new URL(window.location.href).searchParams.get("promotions");
    const practitionerPreference = new URL(window.location.href).searchParams.get("profile_preference_loaded");
    if (date) url.searchParams.append("date", date);
    if (time) url.searchParams.append("time", time);
    if (attendanceMode) url.searchParams.append("attendance_modes[]", attendanceMode);
    if (gender) url.searchParams.append("gender", gender);
    if (acceptsBulkBilling) url.searchParams.append("accepts_bulk_billing", acceptsBulkBilling);
    if (promotions) url.searchParams.append("promotions", promotions);
    if (practitionerPreference) url.searchParams.append("profile_preference_loaded", practitionerPreference);

    url.searchParams.append("partial", "true");

    this.selectedProfessions.forEach(profession => url.searchParams.append("professions[]", profession));
    this.selectedSpecialties.forEach(specialty => url.searchParams.append("specialties[]", specialty));
    this.textSearchParams.forEach(query => url.searchParams.append("query[]", query));
    this.fetchPractitioners(url)
      .then(html => {
        url.searchParams.delete("partial");
        this.practitionerResults.innerHTML = html;
        window.history.pushState({}, "Search", url);
        this.closeDropdown();
      })
      .catch(e => Rollbar.error(e))
  }

  // Fetch practitioners with url appended with search params
  fetchPractitioners(url) {
    return fetchHTML(url);
  }

  openDropdown() {
    if (this.searchValue === "") {
      this.setDefaults();
    }

    this.addHighlight();
    this.updateVisibility();
  }

  closeDropdown() {
    this.removeHighlight();
    this.searchProfessionsTarget.classList.add("hidden");
    this.searchSpecialtiesTarget.classList.add("hidden");
    this.searchTextContainerTarget.classList.add("hidden");
  }

  // Toggle visibility of search button if tags have been selected
  toggleSearchButton() {
    if (this.selectedTags.length > 0) {
      this.searchButtonContainerTarget.classList.remove("hidden");
      return;
    }

    this.searchButtonContainerTarget.classList.add("hidden");
  }

  // Close dropdown if user clicks outside of search container and dropdown
  clickOutsideSearch(event) {
    event.stopPropagation();
    hideOnClickOutside(this.searchProfessionsTarget, "hidden", "add");
    hideOnClickOutside(this.searchSpecialtiesTarget, "hidden", "add");
    hideOnClickOutside(this.searchTextContainerTarget, "hidden", "add");
    hideOnClickOutside(this.searchbarTarget, "highlight", "remove");
  }

  addHighlight() {
    this.searchbarTarget.classList.add("highlight");
  }

  removeHighlight() {
    this.searchbarTarget.classList.remove("highlight");
  }

  get searchValue() {
    return this.inputTarget.value;
  }

  get specialtiesUrl() {
    return this.data.get("specialtiesUrl");
  }

  get practitionersUrl() {
    return this.data.get("practitionersUrl");
  }

  get allProfessions() {
    return JSON.parse(this.data.get("allProfessions"));
  }
}