import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "profileBanner",
    "profileHeader",
    "bookingUi"
  ]

  initialize() {
    this.setProfileBannerHeight();
    this.adjustBookingUi();

    window.addEventListener('resize', this.setProfileBannerHeight);
    window.addEventListener("resize", this.adjustBookingUi);
  }

  disconnect() {
    window.removeEventListener('resize', this.setProfileBannerHeight)
    window.removeEventListener("resize", this.adjustBookingUi);
  }

  setProfileBannerHeight = () => {
    this.profileBannerTarget.style.height = `${this.profileHeaderTarget.clientHeight}px`;
  }

  adjustBookingUi = () => {
    if (!this.hasBookingUiTarget) return;
    if (document.body.classList.contains("booking-ui-open")) {
      if (window.innerWidth > 1079) {
        this.bookingUiTarget.classList.remove("open");
        this.bookingUiTarget.style.top = "";
        document.body.classList.remove("booking-ui-open");
        document.body.style.top = "";
      }
      return;
    }
    if (window.innerWidth <= 1079) {
      this.bookingUiTarget.style.top = `${window.innerHeight - 94}px`;
    } else {
      this.bookingUiTarget.style.top = "";
    }
  }
}
