import { Controller } from "stimulus";
import tippy from "tippy.js";
import { eventDispatcher} from "../js/event_dispatcher";

export default class extends Controller {
  static targets = [
    "bulkbillable",
    "promotions",
    "male",
    "female"
  ]

  initialize() {
    this.acceptsPromotions = false;
    this.bulkBillable = false;
    this.malePreference = false;
    this.femalePreference = false;
    this.genderPreference = "";
    this.setPromotionsFilterFromUrl();
    this.setBulkBillableFromUrl();
    this.setGenderFilterFromUrl();
    this.configureTippy();
  }

  connect() {
    document.addEventListener('keyup', this.handleEscapeKeyPress);
    document.addEventListener('filter_tag:removed', this.handleTagRemove);
  }

  disconnect() {
    document.removeEventListener('keyup', this.handleEscapeKeyPress);
  }

  handleEscapeKeyPress = e => {
    if (e.key === "Escape") {
      this.hideTippy();
    }
  }

  handleTagRemove = e => {
    const { type, value } = e.detail;
    const target = this[`${value}Target`];

    if (type !== "other_filter") return;

    target.checked = false;
    if (value == "promotions") this.togglePromotions({ target: target });
    if (value == "bulkbillable") this.toggleBulkBillable({ target: target });
    if (value == "male") this.toggleMalePreference({ target: target });
    if (value == "female") this.toggleFemalePreference({ target: target });
  }

  configureTippy = () => {
    this.element.classList.remove("hidden");
    const other = document.querySelector(".other");
    const chevron = other.childNodes[1];

    tippy.setDefaultProps({
      duration: 0,
      arrow: true,
      theme: "light-border"
    });

    tippy('.other', {
      content: this.element,
      interactive: true,
      trigger: 'click',
      placement: 'bottom-start',
      maxWidth: '',
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            enabled: true
          },
        ],
      },
      zIndex: 2,
      onShown() {
        chevron.classList.add("open");
        other.classList.add("highlight");
      },
      onHidden() {
        chevron.classList.remove("open");
        other.classList.remove("highlight");
      }
    });
  }

  hideTippy() {
    const popper = document.querySelector('.tippy-popper')
    const instance = popper._tippy
    instance.hide();
  }

  togglePromotions = e => {
    this.acceptsPromotions = e.target.checked ? true : false;
    eventDispatcher.dispatch("other_filter:promotions_updated", { accepts_promotions: this.acceptsPromotions });
  }

  toggleBulkBillable = e => {
    this.bulkBillable = e.target.checked ? true : false;
    eventDispatcher.dispatch("other_filter:bulk_billable_updated", { bulk_billable: this.bulkBillable });
  }

  toggleMalePreference = e => {
    this.genderPreference = e.target.checked ? "male" : "";
    this[`femaleTarget`].checked = false;
    eventDispatcher.dispatch("other_filter:gender_preference_updated", { gender: this.genderPreference });
  }

  toggleFemalePreference = e => {
    this.genderPreference = e.target.checked ? "female" : "";
    this[`maleTarget`].checked = false;
    eventDispatcher.dispatch("other_filter:gender_preference_updated", { gender: this.genderPreference });
  }

  setPromotionsFilterFromUrl = () => {
    let url = new URL(window.location.href);
    if (!url) return;
    let hasPromotions = url.searchParams.has("promotions") ? url.searchParams.get("promotions") : false;

    if (hasPromotions == "true") {
      this.acceptsPromotions = true;
      this.promotionsTarget.checked = true;
    }
  }

  setBulkBillableFromUrl = () => {
    let url = new URL(window.location.href);
    if (!url) return;
    let bulkBillable = url.searchParams.has("accepts_bulk_billing") ? url.searchParams.get("accepts_bulk_billing") : false;

    if (bulkBillable == "true") {
      this.bulkBillable = true;
      this.bulkbillableTarget.checked = true;
    }
  }

  setGenderFilterFromUrl = () => {
    let url = new URL(window.location.href);
    if (!url) return;
    let hasGenderPreference = url.searchParams.has("gender") ? url.searchParams.get("gender") : false;

    if (hasGenderPreference == "true") {
      this.malePreference = true;
      this.malePreference.checked = true;
    }
  }
}
