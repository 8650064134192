import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = ["questionList", "category"]

  connect() {
    this.initializeSortable()
  }

  initializeSortable() {
    this.categoryTargets.forEach(category => {
      const questionList = category.querySelector('[data-drag-and-drop-target="questionList"]')
      new Sortable(questionList, {
        group: category.dataset.categoryId,
        animation: 150,
        draggable: ".draggable-handle",
        handle: ".draggable-handle",
        ghostClass: "sortable-ghost",
        chosenClass: "sortable-chosen",
        onStart: (evt) => {
          evt.from.closest('.b-intake-form--questions-container').classList.add('drag-active')
        },
        onEnd: (evt) => {
          evt.from.closest('.b-intake-form--questions-container').classList.remove('drag-active')
          this.updatePositions(evt)
        }
      })
    })
  }

  updatePositions(event) {
    const categoryElement = event.item.closest('[data-category-id]');
    const categoryId = categoryElement.dataset.categoryId;
    const questionElements = categoryElement.querySelectorAll('[data-question-id]');

    const positions = Array.from(questionElements).map((element, index) => ({
      id: element.dataset.questionId,
      position: index + 1
    }));

    fetch(this.data.get("updateUrl"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      },
      body: JSON.stringify({
        category_id: categoryId,
        positions: positions
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update positions')
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  getCurrentPositions() {
    return this.categoryTargets.flatMap(category =>
      Array.from(category.querySelectorAll('[data-question-id]')).map((question, index) => ({
        id: question.dataset.questionId,
        position: index + 1,
        category_id: category.dataset.categoryId
      }))
    )
  }

  deleteQuestion(event) {
    const questionElement = event.target.closest('[data-question-id]');
    const categoryElement = questionElement.closest('[data-category-id]');

    if (!categoryElement || !questionElement) return;

    const categoryId = categoryElement.dataset.categoryId;
    const questionId = questionElement.dataset.questionId;

    const positions = Array.from(
      categoryElement.querySelectorAll('[data-question-id]'),
      (el, index) => ({ id: el.dataset.questionId, position: index + 1 })
    );

    fetch(this.data.get("destroyUrl"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      },
      body: JSON.stringify({ id: questionId, category_id: categoryId, positions: positions })
    })
      .then(response => {
        if (!response.ok) throw new Error('Failed to delete question');
        questionElement.remove();
      })
      .catch(error => console.error('Error:', error));
  }

}