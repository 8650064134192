import ExtendableError from "extendable-error-class";

class FetchStatusError extends ExtendableError {
  constructor(message, response) {
    super(message);

    this.status = response.status;
    this.statusText = response.statusText;
  }
}

export default FetchStatusError;