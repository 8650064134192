import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    let opts = {
      app_id: this.workspaceId,
      "custom_launcher_selector": ".intercom-launcher",
      hide_default_launcher: true,
      vertical_padding: 200,
    }

    if (this.email) {
      opts.email = this.data.get("email");
    }

    if (this.createdAt) {
      opts.created_at = this.createdAt;
    }

    if (this.userHash) {
      opts.user_hash = this.userHash;
    }

    if (this.userId) {
      opts.user_id = this.userId;
    }

    window.Intercom("boot", opts);
  }

  get workspaceId() {
    return this.data.get("workspace-id")
  }

  get email() {
    return this.data.get("email")
  }

  get userId() {
    return this.data.get("user-id")
  }

  get createdAt() {
    return this.data.get("created-at")
  }

  get userHash() {
    return this.data.get("user-hash")
  }
}
