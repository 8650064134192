import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "content",
  ]

  toggle = () => {
    this.element.classList.toggle("open");
  }
}
