import { Controller } from "stimulus"
import { eventDispatcher } from "../../shared/js/event_dispatcher";
import { fetchJSON } from "../js/helper_functions";

export default class extends Controller {
  static targets = [
    "input",
    "message",
    "button"
  ]

  initialize() {
    this.promotionCode = null;
    // Need setTimeout to wait for prefilling of form input from navigation
    setTimeout(() => {
      this.validatePromotion();
      this.toggleButton();
    }, 0);
  }

  validatePromotion = event => {
    if (event) event.preventDefault();
    this.inputTarget.value = this.inputTarget.value.trim();
    this.promotionCode = this.inputTarget.value;
    this.removeMessage();

    if (!this.promotionCode) return;

    this.fetchPromotion()
      .then(json => {
        this.showMessage(`Promotion Applied - ${json.promotion.formatted_amount} Discount`, { success: true });
        eventDispatcher.dispatch("promotion_validator:success", json);
      })
      .catch(e => {
        this.promotionCode = null;
        if (e.message) {
          this.showMessage(e.message, { error: true });
        }
        eventDispatcher.dispatch("promotion_validator:error", e.message);
      })
  }

  fetchPromotion = () => {
    let url = new URL(this.url)
    url.searchParams.append("promotion_code", this.promotionCode)
    url.searchParams.append("practitioner_id", this.practitionerId)

    return fetchJSON(url);
  }

  toggleButton = () => {
    this.buttonTarget.classList.toggle("hidden", !this.inputTarget.value)
  }

  showMessage(message, options = {}) {
    this.messageTarget.textContent = message;
    this.messageTarget.style.display = "block";
    if (options.error) {
      this.element.classList.add("error");
      this.messageTarget.classList.add("error");
    }
    if (options.success) {
      this.element.classList.add("success");
      this.messageTarget.classList.add("success");
    }
  }

  removeMessage() {
    this.messageTarget.textContent = "";
    this.messageTarget.style.display = "none";
    this.messageTarget.classList.remove("error");
    this.messageTarget.classList.remove("success");
    this.element.classList.remove("error");
    this.element.classList.remove("success");
  }

  get url() {
    return this.data.get("url");
  }

  get practitionerId() {
    return this.data.get("practitionerId");
  }
}