import { Turbo } from '@hotwired/turbo-rails';
import { fetchBASIC } from "../../shared/js/helper_functions";
const MODAL_CONTAINER = "#sidebar-modal-container";
const MODAL_CONTENT = "#sidebar-modal-content";
const MODAL_OVERLAY = ".sidebar-modal-overlay";
const VISIBLE_CLASS = "is-visible";
const HIDDEN_CLASS = "hidden";
const MODAL_OPEN_CLASS = "modal-open";

class ModalService {
  constructor() {
    document.addEventListener("click", e => {
      const isModalLink = e.target.closest("a[data-modal]");
      if (!isModalLink) return;

      e.preventDefault();
      e.stopPropagation();
      // Check current and parent for the href, in case a button icon
      // is clicked instead of the text.
      let href = e.target.href || e.target.parentNode.href;

      this.fetchAndOpen(href);
    })

    document.addEventListener("ajax:before", e => {
      if (e.target.tagName !== "FORM") return;

      if (this.modalIsOpen()) {
        // Add modal: true to the form data
        var input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", "modal");
        input.setAttribute("value", "true");
        e.target.append(input)
      }
    })

    document.addEventListener("ajax:complete", e => {
      return this.render_response_in_modal(e);
    })
  }

  render_response_in_modal(event) {
    let [xhr, response, status] = event.detail;
    if (this.modalIsOpen() && xhr.getResponseHeader("x-modal") == "true") {
      this.populateAndOpen(xhr.response);
      return false;
    }

    return true;
  }

  modalIsOpen() {
    return document.querySelector(MODAL_CONTENT).classList.contains(VISIBLE_CLASS);
  }

  hideModal() {
    document.querySelectorAll(`${MODAL_CONTAINER} .is-visible`).forEach(node => {
      node.classList.remove(VISIBLE_CLASS);
    });
    document.querySelector(MODAL_CONTENT).innerHTML = '';
  }

  fetchAndOpen(href) {
    let url = new URL(href, window.location.origin);
    url.searchParams.append("modal", "true");
    fetchBASIC(url)
      .then(response => {
        let modalRedirect = response.headers.get('modal-redirect');
        if (modalRedirect) {
          Turbo.clearCache();
          this.hideModal();
          Turbo.visit(modalRedirect, { action: "replace" });
          return;
        }
        return response.text();
      })
      .then(html => {
        if (!html) return;

        this.populateAndOpen(html);
      })
  }

  populateAndOpen = data => {
    const content = document.querySelector(MODAL_CONTENT);
    const overlay = document.querySelector(MODAL_OVERLAY);

    content.innerHTML = data;
    content.classList.remove(HIDDEN_CLASS);
    content.classList.add(VISIBLE_CLASS);
    overlay.classList.add(VISIBLE_CLASS);

    // add class used to hide intercom when sidebar is open
    document.body.classList.add(MODAL_OPEN_CLASS);
  }
}

export let modalService = new ModalService();
