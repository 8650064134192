import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
  }

  show(e) {
    window.Intercom("show");
    e.preventDefault();
  }
}
