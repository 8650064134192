import { Controller } from "stimulus"
import tippy from "tippy.js"
import { fetchJSON } from '../../shared/js/helper_functions';
import { notifyService } from "../../shared/js/notify_service";
import TimeoutPromise from "../js/timeout_promise";

export default class extends Controller {
  static targets = [
    'formTitle',
    'formDescription',
    'question',
    'searchInputField',
    'questionGroup',
    'questionTemplate',
    'saveButton',
    'tooltip',
    'tooltipMessage',

    'multiSelect',
    'multiSelectDropdown',
    'multiSelectText',
    'multiSelectMenu',
    'row',
    'selectAllCheckbox',
    'rowCheckbox',
    'uploadButton',
    'searchInput',
    'backButton',
    'categoryCheckbox'
  ]

  // DEV-NOTE: features have been commented out for now
  // https://healthbank.atlassian.net/jira/software/projects/DEV/boards/4?selectedIssue=DEV-768
  // https://healthbank.atlassian.net/jira/software/projects/DEV/boards/4?selectedIssue=DEV-842
  // https://healthbank.atlassian.net/jira/software/projects/DEV/boards/4?selectedIssue=DEV-822


  // initialize() {
  //   if (this.formTitlePresent === "true") {
  //     this.formTitleTarget.value = this.title
  //   }
  //   this.answers = [];
  //   this.formTitle
  //   this.enableButton()
  // }
  //
  initialize() {
    tippy(this.saveButtonTarget.parentElement, { placement: "top-center" })
    this.formTitle = this.formTitleTarget.value;
    this.formDescription = this.formDescriptionTarget.value;

    this.selectedQuestions = this.questionTargets.filter(question => question.checked).map(question => question.value);
    this.searchKey = ''

    if (this.formTitle.length !== 0) {
       this.enableButton()
     } else {
       this.disableButton()
     }
  };

  setSelectedQuestions(targets) {
    targets.forEach(target => {
      if (target.checked) {
        this.selectedQuestions = this.selectedQuestions.concat(target.value);
      } else {
        this.selectedQuestions = this.selectedQuestions.filter(q => q != target.value);
      }
    });

    this.selectedQuestions = [...new Set(this.selectedQuestions)]
    this.refreshQuestionsTable();
  }

  searchInput(event) {
    this.searchKey = event.target.value;
    this.searchQuestions();
  }

  clearSearch() {
    this.searchKey = '';
    this.searchInputFieldTarget.value = ''
    this.searchQuestions();
  }

  searchQuestions() {
    this.questionTargets.forEach(question => {
      if (this.searchQuestionQuery(question)) {
        question.closest('.b-accordion-questions-content').classList.remove('hidden');
      } else {
        question.closest('.b-accordion-questions-content').classList.add('hidden');
      }
    });

    this.refreshQuestionsTable();
  }

  refreshQuestionsTable() {
    this.questionGroupTargets.forEach(questionGroup => {
      let visibleQuestions = questionGroup.querySelectorAll('.b-accordion-questions-content:not(.hidden)')
      let activeQuestions = questionGroup.querySelectorAll('[data-target="form-template.question"]:checked')

      questionGroup.querySelector('span.question-count').innerHTML = visibleQuestions.length
      questionGroup.querySelector('.active-questions-count').innerHTML = activeQuestions.length

      if (activeQuestions.length === 0) {
        questionGroup.querySelector('.active-questions-count').classList.add('hidden')
      } else {
        questionGroup.querySelector('.active-questions-count').classList.remove('hidden')
      }

      if (visibleQuestions.length === 0) {
        questionGroup.classList.add('b-hidden')
      } else {
        questionGroup.classList.remove('b-hidden')

        let expandedGroup = questionGroup.classList.contains('is-expanded')
        let accordionContent = questionGroup.querySelector('.b-question-content');

        if (expandedGroup) {
          accordionContent.style.maxHeight = accordionContent.scrollHeight + "px";
        }
      }
    });
  }

  searchQuestionQuery(question) {
    const query = this.searchKey.toLowerCase();

    if (question.dataset.label.toLowerCase().includes(query)) return true;
    if (question.dataset.category.toLowerCase().includes(query)) return true;
    if (question.dataset.section.toLowerCase().includes(query)) return true;
    return false;
  };

  addNewCustomQuestion(event) {
    let questionTemplate = this.questionTemplateTargets[this.questionTemplateTargets.length - 1].cloneNode(true);
    let newQuestion = event.detail.customQuestion;
    let customQuestionsGroup = this.questionGroupTargets.find(group => group.dataset.category === newQuestion.category).querySelector('.b-question-content');

    this.refreshQuestionRow(questionTemplate, newQuestion);

    customQuestionsGroup.appendChild(questionTemplate);

    this.refreshQuestionsTable();

    notifyService.notify('info', `Succesfully added custom question: ${newQuestion.label}`);
  }

  editCustomQuestion(event) {
    let editedQuestion = event.detail.customQuestion;
    let questionRow =
      this.questionTargets
        .find(question => question.value === editedQuestion.id)
        .closest('[data-target="form-template.questionTemplate"]');

    this.refreshQuestionRow(questionRow, editedQuestion);

    this.refreshQuestionsTable();
  }

  deleteCustomQuestion(event) {
    let deletedQuestion = event.detail.customQuestion;
    let questionRow =
      this.questionTargets
        .find(question => question.value === deletedQuestion.id)
        .closest('[data-target="form-template.questionTemplate"]');

    questionRow.remove();

    this.refreshQuestionsTable();
  }

  refreshQuestionRow(row, question) {
    let templateId = row.querySelector('input[type=checkbox]').dataset.formTemplateId;
    const checkBox = row.querySelector('input[type=checkbox]');

    row.querySelector('.question-label').innerHTML = question.label;
    row.querySelector('.question-type').innerHTML = question.question_type;
    checkBox.value = question.id
    checkBox.checked = true
    let editLink = row.querySelector('.b-accordion-questions-content--label a')

    if (editLink) {
      editLink.remove()
    }

    editLink = document.createElement('a')
    editLink.href = this.editQuestionUrl(question.id, templateId)
    editLink.text = 'Edit'
    editLink.setAttribute("data-modal", true)
    row.querySelector('.b-accordion-questions-content--label').appendChild(editLink);

    if (question.answer_labels) {
      let answerLabels = question.answer_labels.substring(0, 27)

      if (question.answer_labels.length >= 27) answerLabels = answerLabels + '...';

      row.querySelector('.answer-labels').innerHTML = answerLabels
    } else {
      row.querySelector('.answer-labels').innerHTML = null;
    }

    this.setSelectedQuestions([checkBox]);

    return row;
  }

  editQuestionUrl(questionId, templateId) {
    return `/app/practitioners/custom_form_questions/${questionId}/edit?form_template_id=${templateId}`
  };

  toggleSelectQuestion = event => {
    event.stopPropagation();
    const row = event.target.closest('.shared-row-child');
    const checked = event.target.checked;
    row.classList.toggle('is-selected', checked);

    let parent = event.target.closest('.order-product-details-accordion-slide')
    let parentCheckbox = parent.querySelectorAll(".shared-row")[0]
    let test = parentCheckbox.getElementsByClassName("checkbox")

    let children = parent.querySelectorAll(".child")

    let are_all_checked = true
    children.forEach(child => {
      if (!child.checked){
        are_all_checked = false
      }
    })

    let are_all_unchecked = true
    children.forEach(child => {
      if (child.checked){
        are_all_unchecked = false
      }
    })

    if (are_all_unchecked == true && checked == false){
      test[0].checked = false
      test[0].style.cssText += 'border-color:#FFFFFF;background-color:#FFFFFF';
    } else if (test[0].checked == true && checked == false) {
      test[0].style.cssText += 'border-color:#b3c0ce;background-color:#b3c0ce';
    } else if (are_all_checked == true && checked == true){
      test[0].checked = true
      test[0].style.cssText += 'border-color:#28c6c7;background-color:#28c6c7';
    } else if(test.length == 1 && are_all_checked == false) {
      test[0].checked = true
      test[0].style.cssText += 'border-color:#b3c0ce;background-color:#b3c0ce';
    }


    this.setSelectedQuestions([event.target]);
  }


  getFormTitle = event => {
    let formTitle = event.target.value;
    this.formTitle = formTitle
    this.formTitleTarget.value = formTitle

    if (this.formTitle.length !== 0) {
       this.enableButton()

       this.tooltipTarget.classList.add("hidden");
       this.tooltipTarget.classList.remove("is-visible");
       this.formTitleTarget.classList.remove("error");
     } else {
       this.disableButton()

       this.formTitleTarget.placeholder = "Please add title..."
       this.formTitleTarget.classList.toggle("error")
       this.toggleInvalidInputTooltip()
       window.scrollTo({ top: 0, behavior: 'smooth' });
     }
  }

   disableButton() {
     const saveButton = this.saveButtonTarget;
     this.saveButtonTarget.parentElement._tippy.enable()

     saveButton.disabled = true
   }

   toggleInvalidInputTooltip() {
     const tooltip = this.tooltipTarget;

     tooltip.classList.add("is-visible");
     tooltip.classList.remove("hidden");
   }

   enableButton() {
     const saveButton = this.saveButtonTarget;
     this.saveButtonTarget.parentElement._tippy.disable()
     saveButton.disabled = false
   }

  getFormDescription = event => {
    let formDescription = event.target.value;
    this.formDescription = formDescription
    this.formDescriptionTarget.value = formDescription
  }

  getAnswerLabel = event => {
    const value = event.target.value;
    const answerId = event.target.dataset.answerId
    var index = _.findIndex(this.answers, {answerId});
    if (index !== -1){
      this.answers.splice(index, 1, {answerId, value});
    } else {
      this.answers.push({answerId, value})
    }
  }

  toggleSelectCategory = event => {
    event.stopPropagation();
    const row = event.target.closest('.shared-row');
    const checked = event.target.checked;

    let category = event.target.value;
    category = category.replace(/\s+/g, '-').toLowerCase()

    const allBoxesInCategory = document.querySelectorAll(`.checkbox.${category}`)
    if (checked) {
      allBoxesInCategory.forEach(element => {
        element.checked = true
      });
      event.target.style.cssText += 'border-color:#28c6c7;background-color:#28c6c7';
    } else {
      allBoxesInCategory.forEach(element => {
        element.checked = false
      });
      event.target.style.cssText += 'border-color:#FFFFFF;background-color:#FFFFFF';
    }

    this.setSelectedQuestions(allBoxesInCategory);
    row.classList.toggle('is-selected', checked);
  }


  get checkedAttachmentIds() {
    const filesChecked = document.querySelectorAll('.shared-body .shared-checkbox:checked');
    return Array.from(filesChecked).map(checkbox => checkbox.value);
  }

  addQuestion = e => {
    return;
    // if (!this.formTitleTarget.value) {
    //     this.disableButton()
    // } else {
      e.preventDefault();
      fetchJSON(this.addQuestionUrl, {
        method: "PATCH",
        data: {
          attachment_ids: this.checkedAttachmentIds,
          form_template_id: this.formId,
          title: this.formTitle,
          description: this.formDescription
        }
      })
      .then(response => {
        if (!response.success) throw "Bulk Delete Failed";
        const redirectUrl = this.editFormUrl;
        window.location = redirectUrl;
        notifyService.notify("info", "Questions Added!");
      })
    // }
  }

  updateForm(e) {
    const button = e.target;

    e.preventDefault();
    button.innerHTML = this.disabledText;
    button.disabled = true;

    fetchJSON(this.updateFormUrl, {
      method: "PATCH",
      data: {
        form_template_id: this.formId,
        title: this.formTitle,
        description: this.formDescription,
        selected_questions: this.selectedQuestions
      }
    })
    .then(response => {
      if (!response.success) {
        button.disabled = false;
        button.innerHTML = this.submitText;
        throw "Updated Failed"
      };
      const redirectUrl = this.formTemplateEditUrl;

      notifyService.notify('info', 'Form succesfully updated');
      new TimeoutPromise().timeout(1000).then(() => {
        window.location = redirectUrl;
      });
    })
  }

  addCustomAnswer = e => {
    e.preventDefault();
      fetchJSON(this.addAnswerUrl, {
        method: "PATCH",
        data: {
          form_question_id: this.answerId,
          answers: this.answers
        }
      })
      .then(response => {
        if (!response.success) throw "Updated Failed";
        window.location = response.redirectUrl;
        location.reload();
      })
  }

  get addQuestionUrl() {
    return this.data.get("addQuestionUrl");
  }

  get addAnswerUrl() {
    return this.data.get("addAnswerUrl");
  }

  get addAnswerUrl() {
    return this.data.get("addAnswerUrl");
  }

  get formTemplateEditUrl() {
    return this.data.get("formTemplateEditUrl");
  }

  get updateFormUrl() {
    return this.data.get("updateFormUrl");
  }

  get formId() {
    return this.data.get("formId");
  }

  get formId() {
    return this.data.get("formId");
  }

  get formTitlePresent() {
    return this.data.get("formTitlePresent");
  }

  get title() {
    return this.data.get("title");
  }

  get editFormUrl() {
    return this.data.get("editFormUrl");
  }

  get submitText() {
    return this.data.get("submitText");
  }

  get disabledText() {
    return this.data.get("disabledText");
  }
}
