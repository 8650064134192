class TimeoutPromise {
  constructor() {
  }

  timeout(interval) {
    return new Promise(resolve => {
      setTimeout(resolve, interval);
    });
  }
}

export default TimeoutPromise;
